import React from "react";
import styled, { css } from "styled-components";
import Php from "../../../Backend/Php";
import { useNotification } from "../../Notification/NotificationContext";
import { NewTheme } from "../../Theme/Theme";

import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

const php = new Php();

const RightPanelContainer = styled.div`
  flex: 3;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border-left: 0px solid black; /* Add a 1px black border on the left side */
`;

export const BalanceWrapper = styled.div`
  /* border-bottom: 1px solid lightgray; */
  /* margin-bottom: 20px; */
  display: flex; /* Make child elements horizontal */
  flex-direction: column;
  width: 90%;
  justify-content: space-between; /* Add space between children */
`;

export const BalanceContainer = styled.div`
  margin-bottom: 20px;
  display: flex; /* Make child elements horizontal */
  align-items: center; /* Vertically center align child elements */
  justify-content: space-between; /* Add space between children */
`;

export const BalanceInput = styled.input`
  background-color: white;
  color: rgb(0, 0, 0);
  transition: all 0.2s ease 0s;
  height: 40px;
  border-radius: 5px;
  width: 50%;
  border: 0.5px solid lightgray;
  padding-left: 10px;
  ${(props) =>
    props.disabled &&
    css`
      background-color: #edebfa;
      color: ${NewTheme.MainColor};
      font-weight: bold;
    `}
`;

export const BalanceUpdateButton = styled.button`
  display: flex; /* Center content horizontally and vertically */
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 150px;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  color: white;
  padding: 14px 28px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  margin: 0px;
  background-color: ${NewTheme.MainColor};
  border-color: ${NewTheme.MainColor};
  align-self: center;

  &:focus-visible {
    border-color: #6366f1;
    color: #ffffff;
    border-width: 3px;
    border: 1px solid #ffffff;
  }
`;

export const BalanceLabel = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  padding-left: 5px;
  padding-right: 10px;
  color: gray;
  text-align: left;
`;

export const BalanceAmountText = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  padding-left: 20px;
  color: gray;
`;

export default function Edit(props) {
  const [loading, setLoading] = React.useState(false);
  const [clientData, setClientData] = React.useState([]);
  const { addNotification } = useNotification();
  const [mcxVolumeWise, setMcxVolumeWise] = React.useState(0);
  const [error, setError] = React.useState("");
  const [name, setName] = React.useState("");
  const [username, setUserame] = React.useState("");
  const [alias, setAlias] = React.useState("");
  const [fsqPercent, setFsqPercent] = React.useState(95);
  const [futBrokerage, setFutBrokerage] = React.useState(0);
  const [mcxBrokerage, setMcxBrokerage] = React.useState("");
  const [foBrokerage, setFoBrokerage] = React.useState("");

  const [forexBrokerage, setForexBrokerage] = React.useState("");
  const [cryptoBrokerage, setCryptoBrokerage] = React.useState("");
  const [stocksBrokerage, setStocksBrokerage] = React.useState("");

  const [password, setPassword] = React.useState("");

  const [brokerList, setBrokerList] = React.useState([]);
  const [brokerId, setBrokerId] = React.useState(0);

  const [masterList, setMasterList] = React.useState([]);
  const [masterId, setMasterId] = React.useState(0);

  const [brokerFutBrokerage, setBrokerFutBrokerage] = React.useState("");
  const [brokerFutBrokeragePercent, setBrokerFutBrokeragePercent] =
    React.useState(0);
  const [brokerMcxBrokerage, setBrokerMcxBrokerage] = React.useState("");
  const [brokerMcxBrokeragePercent, setBrokerMcxBrokeragePercent] =
    React.useState(0);
  const [brokerFoBrokerage, setBrokerFoBrokerage] = React.useState("");
  const [brokerFoBrokeragePercent, setBrokerFoBrokeragePercent] =
    React.useState(0);

  const [brokerSharingPercent, setBrokerSharingPercent] = React.useState(0);

  const [brokerForexBrokerage, setBrokerForexBrokerage] = React.useState("");
  const [brokerForexBrokeragePercent, setBrokerForexBrokeragePercent] =
    React.useState(0);

  const [brokerCryptoBrokerage, setBrokerCryptoBrokerage] = React.useState("");
  const [brokerCryptoBrokeragePercent, setBrokerCryptoBrokeragePercent] =
    React.useState(0);

  const [brokerStocksBrokerage, setBrokerStocksBrokerage] = React.useState("");
  const [brokerStocksBrokeragePercent, setBrokerStocksBrokeragePercent] =
    React.useState(0);
  // const marginLocal = JSON.parse(localStorage.getItem("mastersDetails")).margin;

  const [masterBrokearge, setMasterBrokearge] = React.useState([]);

  // React.useEffect(() => {
  //   load_master_brokerages(props.data.master_id);
  // }, []);

  // React.useEffect(() => {
  //   loadBrokers(props.data.master_id);
  // }, []);

  // React.useEffect(() => {
  //   loadMasters();
  // }, []);

  const loadMasters = (e) => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
        a_id: props.data.admin_id,
      };

      php.load_all_masters(data).then((r) => {
        console.log(r);
        setLoading(false);
        if (r.error === "False") {
          setMasterList(r.masters);
          const filteredUsers = r.masters.filter(
            (rx) => Number(props.data.master_id) === Number(rx.id) // Ensure type compatibility
          );
          if (filteredUsers.length > 0) {
            // Assuming you want to set the first matched user or handle multiple users differently
            const firstUser = filteredUsers[0]; // Get the first matched user

            setMasterId({
              id: firstUser.id,
              username: firstUser.username,
              status: "1",
            });
            load_master_brokerages(firstUser.id);
            loadBrokers(firstUser.id);
          }
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const load_master_brokerages = (e) => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
        m_id: e,
      };
      php.load_master_brokerages(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          setMasterBrokearge(r.masters_brokerage);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const loadBrokers = (e) => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
        m_id: e,
      };
      php.load_masters_brokers(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          setBrokerList(r.brokers);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  React.useEffect(() => {
    load_users_data();
  }, [props.data.id]);
  console.log(props.data);
  const load_users_data = (e) => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
        c_id: props.data.id,
        a_id: props.data.admin_id,
        m_id: props.data.master_id,
      };
      console.log(data);
      php.load_users_data(data).then((r) => {
        console.log(r);
        setLoading(false);
        if (r.error === "False") {
          setClientData(r.users);
          setName(r.users?.name);
          setAlias(r.users?.alias != null ? r.users?.alias : "");
          setUserame(r.users?.username);
          setFoBrokerage(r.users?.fo_brokerage);
          setMcxBrokerage(r.users?.mcx_brokerage);
          setFutBrokerage(parseFloat(r.users?.fut_brokerage).toFixed(3));
          setFsqPercent(parseFloat(r.users?.fsq_percent));
          setForexBrokerage(r.users?.forex_brokerage);
          setCryptoBrokerage(r.users?.crypto_brokerage);
          setStocksBrokerage(parseFloat(r.users?.stocks_brokerage));

          if (parseFloat(r.users?.broker_id) > 0) {
            setBrokerId({
              id: r.users?.broker_id,
              username: r.users?.broker_username,
              status: "1",
            });

            setBrokerFoBrokeragePercent(r.users?.fo_percent);
            setBrokerFutBrokeragePercent(r.users?.fut_percent);
            setBrokerMcxBrokeragePercent(r.users?.mcx_percent);

            setBrokerForexBrokeragePercent(r.users?.forex_percent);
            setBrokerCryptoBrokeragePercent(r.users?.crypto_percent);
            setBrokerStocksBrokeragePercent(r.users?.stocks_percent);

            setBrokerSharingPercent(r.users?.sharing);

            setBrokerFoBrokerage(
              parseFloat(
                (r.users?.fo_brokerage * r.users?.fo_percent) / 100
              ).toFixed(3)
            );
            setBrokerFutBrokerage(
              parseFloat(
                (r.users?.fut_brokerage * r.users?.fut_percent) / 100
              ).toFixed(3)
            );
            setBrokerMcxBrokerage(
              parseFloat(
                (r.users?.mcx_brokerage * r.users?.mcx_percent) / 100
              ).toFixed(3)
            );
            setBrokerForexBrokerage(
              parseFloat(
                (r.users?.forex_brokerage * r.users?.forex_percent) / 100
              ).toFixed(3)
            );
            setBrokerCryptoBrokerage(
              parseFloat(
                (r.users?.crypto_brokerage * r.users?.crypto_percent) / 100
              ).toFixed(3)
            );
            setBrokerStocksBrokerage(
              parseFloat(
                (r.users?.stocks_brokerage * r.users?.stocks_percent) / 100
              ).toFixed(3)
            );
          }
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const edit_user = (e) => {
    if (name == "") {
      addNotification("Please Enter Name", "error");
    } else if (masterId?.id < 0) {
      addNotification("Please Select Master", "error");
    } else if (name == "") {
      addNotification("Please Enter Name", "error");
    } else if (fsqPercent < 0 || fsqPercent > 100) {
      addNotification(
        "Please Enter Force SqOff Percent Between 0 to 100 ",
        "error"
      );
    } else if (
      futBrokerage < masterBrokearge?.min_fut_brokerage ||
      futBrokerage > masterBrokearge?.max_fut_brokerage
    ) {
      setError("Future Brokerage");
      addNotification(
        "Enter Min Future Brokerage Between " +
          masterBrokearge?.min_fut_brokerage +
          " & " +
          masterBrokearge?.max_fut_brokerage,
        "error"
      );
    } else if (
      (mcxBrokerage < masterBrokearge?.min_cr_mcx_brokerage ||
        mcxBrokerage > masterBrokearge?.max_cr_mcx_brokerage) &&
      mcxVolumeWise == "1"
    ) {
      setError("Mcx Brokerage");
      addNotification(
        "Enter Min Mcx Brokerage Between " +
          masterBrokearge?.min_cr_mcx_brokerage +
          " & " +
          masterBrokearge?.max_cr_mcx_brokerage,
        "error"
      );
    } else if (
      (parseFloat(mcxBrokerage) < masterBrokearge?.min_mcx_brokerage ||
        parseFloat(mcxBrokerage) > masterBrokearge?.max_mcx_brokerage) &&
      mcxVolumeWise == "0"
    ) {
      setError("Mcx Brokerage");
      addNotification(
        "Enter Min Mcx Brokerage Between " +
          masterBrokearge?.min_mcx_brokerage +
          " & " +
          masterBrokearge?.max_mcx_brokerage,
        "error"
      );
    } else if (
      parseFloat(foBrokerage) < masterBrokearge?.min_fo_brokerage ||
      parseFloat(foBrokerage) > masterBrokearge?.max_fo_brokerage
    ) {
      setError("Fo Brokerage");
      addNotification(
        "Enter Min Fo Brokerage Between " +
          masterBrokearge?.min_fo_brokerage +
          " & " +
          masterBrokearge?.max_fo_brokerage,
        "error"
      );
    } else if (
      parseFloat(forexBrokerage) < masterBrokearge?.min_forex_brokerage ||
      parseFloat(forexBrokerage) > masterBrokearge?.max_forex_brokerage
    ) {
      setError("Forex Brokerage");
      addNotification(
        "Enter Min Forex Brokerage Between " +
          masterBrokearge?.min_forex_brokerage +
          " & " +
          masterBrokearge?.max_forex_brokerage,
        "error"
      );
    } else if (
      parseFloat(cryptoBrokerage) < masterBrokearge?.min_crypto_brokerage ||
      parseFloat(cryptoBrokerage) > masterBrokearge?.max_crypto_brokerage
    ) {
      setError("Crypto Brokerage");
      addNotification(
        "Enter Min Crypto Brokerage Between " +
          masterBrokearge?.min_crypto_brokerage +
          " & " +
          masterBrokearge?.max_crypto_brokerage,
        "error"
      );
    } else if (
      parseFloat(stocksBrokerage) < masterBrokearge?.min_stocks_brokerage ||
      parseFloat(stocksBrokerage) > masterBrokearge?.max_stocks_brokerage
    ) {
      setError("US Stocks Brokerage");
      addNotification(
        "Enter Min US Stocks Brokerage Between " +
          masterBrokearge?.min_stocks_brokerage +
          " & " +
          masterBrokearge?.max_stocks_brokerage,
        "error"
      );
    } else if (brokerId?.id > 0 && brokerFutBrokeragePercent < 0) {
      addNotification(
        "Broker FUTURE Brokerage Must Be Greater Than 0",
        "error"
      );
    } else if (brokerId?.id > 0 && brokerMcxBrokeragePercent < 0) {
      addNotification("Broker MCX Brokerage Must Be Greater Than 1", "error");
    } else if (brokerId?.id > 0 && brokerFoBrokeragePercent < 0) {
      addNotification(
        "Broker OPTION Brokerage Must Be Greater Than 1",
        "error"
      );
    } else if (brokerId?.id > 0 && brokerForexBrokeragePercent < 0) {
      addNotification("Broker FOREX Brokerage Must Be Greater Than 1", "error");
    } else if (brokerId?.id > 0 && brokerCryptoBrokeragePercent < 0) {
      addNotification(
        "Broker CRYTPO Brokerage Must Be Greater Than 1",
        "error"
      );
    } else if (brokerId?.id > 0 && brokerStocksBrokeragePercent < 0) {
      addNotification(
        "Broker US STOCKS Brokerage Must Be Greater Than 1",
        "error"
      );
    } else if (brokerId?.id > 0 && brokerSharingPercent < 0) {
      addNotification("Broker SHARING Must Be Greater Than 1", "error");
    } else {
      if (!loading) {
        setLoading(false);
        let data = {
          sr: localStorage.getItem("server"),
          jwt: localStorage.getItem("token"),
          g_id: localStorage.getItem("godsId"),
          c_id: props.data.id,
          m_id: masterId?.id,
          name: name,
          password: password,
          alias: alias,
          fsq_percent: fsqPercent,
          fut_brokerage: parseFloat(futBrokerage).toFixed(3),
          mcx_brokerage: parseFloat(mcxBrokerage).toFixed(3),
          fo_brokerage: parseFloat(foBrokerage).toFixed(3),
          mcx_volume_wise: parseFloat(mcxVolumeWise).toFixed(3),

          forex_brokerage: parseFloat(forexBrokerage).toFixed(3),
          crypto_brokerage: parseFloat(cryptoBrokerage).toFixed(3),
          stocks_brokerage: parseFloat(stocksBrokerage).toFixed(3),

          ///broker
          broker_id: brokerId?.id,
          broker_fut_brokerage:
            brokerId?.id > 0 ? brokerFutBrokeragePercent : 0,
          broker_mcx_brokerage:
            brokerId?.id > 0 ? brokerMcxBrokeragePercent : 0,
          broker_fo_brokerage: brokerId?.id > 0 ? brokerFoBrokeragePercent : 0,
          broker_sharing:
            brokerId?.id > 0 ? parseFloat(brokerSharingPercent).toFixed(3) : 0,

          broker_forex_brokerage:
            brokerId?.id > 0 ? brokerForexBrokeragePercent : 0,
          broker_crypto_brokerage:
            brokerId?.id > 0 ? brokerCryptoBrokeragePercent : 0,
          broker_stocks_brokerage:
            brokerId?.id > 0 ? brokerStocksBrokeragePercent : 0,
        };

        php.edit_user(data).then((r) => {
          setLoading(false);
          if (r.error === "False") {
            load_users_data();
            props.reload();
            addNotification(r.message, "success");
          } else {
            addNotification(r.message, "error");
          }
        });
      }
    }
  };

  const handleBrokerage = (value) => {
    if (parseFloat(value).toFixed(3) < 1) {
      setMcxVolumeWise("1");
    } else {
      setMcxVolumeWise("0");
    }
    setMcxBrokerage(value);
  };

  function calcFutPercent(fut) {
    var result = (parseFloat(fut) * 100) / parseFloat(futBrokerage);
    var roundedResult = parseFloat(result).toFixed(3);

    if (
      parseFloat(roundedResult) > 100 ||
      parseFloat(roundedResult) < 0 ||
      isNaN(roundedResult)
    ) {
      setBrokerFutBrokerage("");
      setBrokerFutBrokeragePercent(0);
      addNotification("Broker Brokerage Must Between 0 To 100%", "error");
    } else {
      setBrokerFutBrokerage(fut);
      setBrokerFutBrokeragePercent(parseFloat(roundedResult));
    }
  }

  function calcMcxPercent(mcx) {
    var result = (parseFloat(mcx) * 100) / parseFloat(mcxBrokerage);
    var roundedResult = result.toFixed(3);

    if (
      parseFloat(roundedResult) > 100 ||
      parseFloat(roundedResult) < 0 ||
      isNaN(roundedResult)
    ) {
      setBrokerMcxBrokerage("");
      setBrokerMcxBrokeragePercent(0);
      addNotification("Broker Brokerage Must Between 0 To 100%", "error");
    } else {
      setBrokerMcxBrokerage(mcx);
      setBrokerMcxBrokeragePercent(parseFloat(roundedResult));
    }
  }

  function calcFoPercent(fo) {
    var result = (parseFloat(fo) * 100) / parseFloat(foBrokerage);
    var roundedResult = result.toFixed(3);

    if (
      parseFloat(roundedResult) > 100 ||
      parseFloat(roundedResult) < 0 ||
      isNaN(roundedResult)
    ) {
      setBrokerFoBrokerage("");
      setBrokerFoBrokeragePercent(0);
      addNotification("Broker Brokerage Must Between 0 To 100%", "error");
    } else {
      setBrokerFoBrokerage(fo);
      setBrokerFoBrokeragePercent(parseFloat(roundedResult));
    }
  }

  function calcForexPercent(forex) {
    var result = (parseFloat(forex) * 100) / parseFloat(forexBrokerage);
    var roundedResult = result.toFixed(3);

    if (
      parseFloat(roundedResult) > 100 ||
      parseFloat(roundedResult) < 0 ||
      isNaN(roundedResult)
    ) {
      setBrokerForexBrokerage("");
      setBrokerForexBrokeragePercent(0);
      addNotification("Broker Brokerage Must Between 0 To 100%", "error");
    } else {
      setBrokerForexBrokerage(forex);
      setBrokerForexBrokeragePercent(parseFloat(roundedResult));
    }
  }

  function calcCryptoPercent(crypto) {
    var result = (parseFloat(crypto) * 100) / parseFloat(cryptoBrokerage);
    var roundedResult = result.toFixed(3);

    if (
      parseFloat(roundedResult) > 100 ||
      parseFloat(roundedResult) < 0 ||
      isNaN(roundedResult)
    ) {
      setBrokerCryptoBrokerage("");
      setBrokerCryptoBrokeragePercent(0);
      addNotification("Broker Brokerage Must Between 0 To 100%", "error");
    } else {
      setBrokerCryptoBrokerage(crypto);
      setBrokerCryptoBrokeragePercent(parseFloat(roundedResult));
    }
  }

  function calcStocksPercent(stocks) {
    var result = (parseFloat(stocks) * 100) / parseFloat(stocksBrokerage);
    var roundedResult = result.toFixed(3);

    if (
      parseFloat(roundedResult) > 100 ||
      parseFloat(roundedResult) < 0 ||
      isNaN(roundedResult)
    ) {
      setBrokerStocksBrokerage("");
      setBrokerStocksBrokeragePercent(0);
      addNotification("Broker Brokerage Must Between 0 To 100%", "error");
    } else {
      setBrokerStocksBrokerage(stocks);
      setBrokerStocksBrokeragePercent(parseFloat(roundedResult));
    }
  }

  const calcSharing = (value) => {
    let margin_broker =
      masterBrokearge?.margin >= 0 ? masterBrokearge?.margin : "0";

    if (value > parseFloat(margin_broker) || margin_broker <= 0) {
      setBrokerSharingPercent("");
      addNotification("Sharing Must Be Between 0 To " + margin_broker, "error");
    } else {
      setBrokerSharingPercent(value);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        background: "white",
        height: "100%",
        // padding: 10,
        boxSizing: "border-box",
      }}
    >
      <Card
        style={{
          margin: 10,
          position: "relative",
          height: window.innerHeight - 160,
          overflowY: "scroll",
        }}
      >
        <RightPanelContainer>
          <BalanceWrapper>
            <BalanceContainer>
              <BalanceLabel> Master</BalanceLabel>
              <Dropdown
                style={{
                  width: "50%",
                  height: "40px",
                }}
                onChange={(e) => {
                  setMasterId(e.value);
                  setBrokerId({});
                  loadBrokers(e.value?.id);
                }}
                options={masterList}
                value={masterId}
                optionLabel="username"
                placeholder="Select Username"
                filter
                showClear
              />
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel>Name </BalanceLabel>
              <BalanceInput
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter In Name "
              />
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel>Username </BalanceLabel>
              <BalanceInput
                style={{
                  backgroundColor: "#edebfa",
                  color: NewTheme.MainColor,
                  fontWeight: "bold",
                }}
                type="text"
                value={username}
                disabled
              />
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel>Password</BalanceLabel>
              <BalanceInput
                type="text"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter Password"
              />
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel>Alias</BalanceLabel>
              <BalanceInput
                value={alias}
                onChange={(e) => setAlias(e.target.value)}
                placeholder="Enter Client Alias "
              />
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel>Force SqOff % </BalanceLabel>
              <BalanceInput
                type="text"
                value={fsqPercent}
                onChange={(e) => setFsqPercent(e.target.value)}
                placeholder="Enter in Percentage (%)"
              />
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel>Fut Brokerage</BalanceLabel>
              <BalanceInput
                type="number"
                value={futBrokerage}
                onChange={(e) => setFutBrokerage(e.target.value)}
                placeholder="Enter per Cr"
              />
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel>
                Mcx Brokerage
                <br />
                <span>( Cr wise - enter less than 1)</span>
              </BalanceLabel>
              <BalanceInput
                type="number"
                value={mcxBrokerage}
                onChange={(e) => handleBrokerage(e.target.value)}
                placeholder="Enter per Lot"
              />
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel>Option Brokerage</BalanceLabel>
              <BalanceInput
                type="number"
                value={foBrokerage}
                onChange={(e) => setFoBrokerage(e.target.value)}
                placeholder="Enter per Lot"
              />
            </BalanceContainer>

            <BalanceContainer>
              <BalanceLabel>Forex Brokerage</BalanceLabel>
              <BalanceInput
                type="number"
                value={forexBrokerage}
                onChange={(e) => setForexBrokerage(e.target.value)}
                placeholder="Enter per Lot"
              />
            </BalanceContainer>

            <BalanceContainer>
              <BalanceLabel>Crypto Brokerage</BalanceLabel>
              <BalanceInput
                type="number"
                value={cryptoBrokerage}
                onChange={(e) => setCryptoBrokerage(e.target.value)}
                placeholder="Enter per Lot"
              />
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel>US Stocks Brokerage</BalanceLabel>
              <BalanceInput
                type="number"
                value={stocksBrokerage}
                onChange={(e) => setStocksBrokerage(e.target.value)}
                placeholder="Enter per Lot"
              />
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel>Select Broker</BalanceLabel>
              <Dropdown
                style={{
                  width: "50%",
                  height: "40px",
                }}
                onChange={(e) => {
                  setBrokerId(e.value);
                }}
                options={brokerList}
                value={brokerId}
                optionLabel="username"
                placeholder="Select Username"
                filter
                showClear
              />
            </BalanceContainer>
            {brokerId?.id > 0 ? (
              <>
                <BalanceContainer>
                  <BalanceLabel>Broker Future Brokerage</BalanceLabel>
                  <div style={{ width: "50%" }}>
                    <BalanceInput
                      type="number"
                      value={brokerFutBrokerage}
                      onChange={(e) => calcFutPercent(e.target.value)}
                      placeholder="Enter Broker Future Brokerage"
                    />
                    <BalanceInput
                      value={brokerFutBrokeragePercent + "%"}
                      placeholder="0%"
                      disabled
                    />
                  </div>
                </BalanceContainer>
                <BalanceContainer>
                  <BalanceLabel>Broker Mcx Brokerage</BalanceLabel>
                  <div style={{ width: "50%" }}>
                    <BalanceInput
                      type="number"
                      value={brokerMcxBrokerage}
                      onChange={(e) => calcMcxPercent(e.target.value)}
                      placeholder="Enter Broker Mcx Brokerage"
                    />
                    <BalanceInput
                      value={brokerMcxBrokeragePercent + "%"}
                      placeholder="0%"
                      disabled
                    />
                  </div>
                </BalanceContainer>
                <BalanceContainer>
                  <BalanceLabel>Broker Option Brokerage</BalanceLabel>
                  <div style={{ width: "50%" }}>
                    <BalanceInput
                      type="number"
                      value={brokerFoBrokerage}
                      onChange={(e) => calcFoPercent(e.target.value)}
                      placeholder="Enter Broker Fo Brokerage"
                    />
                    <BalanceInput
                      value={brokerFoBrokeragePercent + "%"}
                      placeholder="0%"
                      disabled
                    />
                  </div>
                </BalanceContainer>

                <BalanceContainer>
                  <BalanceLabel>Broker Forex Brokerage</BalanceLabel>
                  <div style={{ width: "50%" }}>
                    <BalanceInput
                      type="number"
                      value={brokerForexBrokerage}
                      onChange={(e) => calcForexPercent(e.target.value)}
                      placeholder="Enter Broker Forex Brokerage"
                    />
                    <BalanceInput
                      value={brokerForexBrokeragePercent + "%"}
                      placeholder="0%"
                      disabled
                    />
                  </div>
                </BalanceContainer>

                <BalanceContainer>
                  <BalanceLabel>Broker Crypto Brokerage</BalanceLabel>
                  <div style={{ width: "50%" }}>
                    <BalanceInput
                      type="number"
                      value={brokerCryptoBrokerage}
                      onChange={(e) => calcCryptoPercent(e.target.value)}
                      placeholder="Enter Broker Crypto Brokerage"
                    />
                    <BalanceInput
                      value={brokerCryptoBrokeragePercent + "%"}
                      placeholder="0%"
                      disabled
                    />
                  </div>
                </BalanceContainer>

                <BalanceContainer>
                  <BalanceLabel>Broker Stocks Brokerage</BalanceLabel>
                  <div style={{ width: "50%" }}>
                    <BalanceInput
                      type="number"
                      value={brokerStocksBrokerage}
                      onChange={(e) => calcStocksPercent(e.target.value)}
                      placeholder="Enter Broker Stocks Brokerage"
                    />
                    <BalanceInput
                      value={brokerStocksBrokeragePercent + "%"}
                      placeholder="0%"
                      disabled
                    />
                  </div>
                </BalanceContainer>

                <BalanceContainer>
                  <BalanceLabel>Broker Sharing</BalanceLabel>

                  <div
                    className="p-inputgroup flex-1"
                    style={{ height: 35, width: "50%" }}
                  >
                    <InputText
                      type="number"
                      value={brokerSharingPercent}
                      onChange={(e) => calcSharing(e.target.value)}
                      placeholder="Enter Sharing"
                    />

                    <span
                      className="p-inputgroup-addon"
                      style={{
                        backgroundColor: NewTheme.MainColor,
                        color: "#ffffff",
                      }}
                    >
                      {"Your Margin : " +
                        (masterBrokearge?.margin - brokerSharingPercent) +
                        "%"}
                    </span>
                  </div>
                </BalanceContainer>
              </>
            ) : null}
            <BalanceUpdateButton onClick={edit_user}>
              UPDATE
            </BalanceUpdateButton>
          </BalanceWrapper>
        </RightPanelContainer>
      </Card>
    </div>
  );
}
