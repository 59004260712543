import { Button } from "primereact/button";
import React, { useState } from "react";
import Php from "../../../Backend/Php";
import { useNotification } from "../../Notification/NotificationContext";
import { NewTheme } from "../../Theme/Theme";
import LiveSummaryModal from "./Modal/LiveSummaryModal";
import MultiplierModal from "./Modal/MultiplierModal";
import {
  BlackText,
  BlueText,
  BoldText,
  ButtonGrid,
  CreateClientButton,
  FlexContainer,
  FlexItem,
  GrayText,
  GreenText,
  Wrapper,
} from "./Styled";
import NumberFormatIn from "../../Common/NumberFormatIn";
import LimitModal from "./Modal/LimitModal";
import DepositModal from "./Modal/DepositModal";
import { faArrowAltCircleRight, faL } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

const php = new Php();

export default function Overview(props) {
  const [loading, setLoading] = React.useState(false);
  const [buttonsData, setButtonsData] = React.useState([]);
  const [clientData, setClientData] = React.useState([]);
  const { addNotification } = useNotification();
  const [timeDiff, setTimeDiff] = useState(0);

  React.useEffect(() => {
    load_users_overview();
  }, [props.data.id]);

  const load_users_overview = () => {
    if (!loading) {
      setLoading(true);
      let data2 = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
        c_id: props.data.id,
      };

      php.load_users_overview(data2).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          setClientData(r.users_overview[0]);
          setButtonsData(r.buttons_data);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const removeMaster = () => {
    var selection = window.prompt(
      "Please Type Client Username to confirm delete Client : " +
        props.data?.username
    );
    if (
      selection == "" ||
      props.data?.username == "" ||
      props.data?.username == undefined
    ) {
      window.alert("Client Username Does Not Match");
    } else if (selection !== props.data?.username) {
      window.alert("Client Username Does Not Match");
    } else {
      setLoading(true);
      let data2 = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
        c_id: props.data.id,
      };

      php.remove_master(data2).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          load_users_overview();
          addNotification(r.message, "success");
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  function handleButtonClick(key) {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
        c_id: clientData.id,
        s_id: clientData.server,
        button_key: key,
      };

      php.change_users_setting(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          load_users_overview();
          if (r.on_off == "0") {
            addNotification(r.message, "error");
          } else {
            addNotification(r.message, "success");
          }
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  }

  function handleUsersMultiplier(fut, mcx, fo, forex, crypto, stocks) {
    if (
      isNaN(parseFloat(fut)) ||
      isNaN(parseFloat(mcx)) ||
      isNaN(parseFloat(fo)) ||
      isNaN(parseFloat(forex)) ||
      isNaN(parseFloat(crypto)) ||
      isNaN(parseFloat(stocks))
    ) {
      window.alert("Only Number Are Allowed");
    } else if (
      parseFloat(fut) <= 0 ||
      parseFloat(mcx) <= 0 ||
      parseFloat(fo) <= 0 ||
      parseFloat(forex) <= 0 ||
      parseFloat(crypto) <= 0 ||
      parseFloat(stocks) <= 0
    ) {
      window.alert("Enter Number Greater than 0");
    } else {
      if (!loading) {
        setLoading(true);
        let data = {
          sr: localStorage.getItem("server"),
          jwt: localStorage.getItem("token"),
          g_id: localStorage.getItem("godsId"),
          c_id: clientData.id,
          fut_multiplier: fut,
          mcx_multiplier: mcx,
          fo_multiplier: fo,
          forex_multiplier: forex,
          crypto_multiplier: crypto,
          stocks_multiplier: stocks,
          s_id: clientData.server,
        };

        php.change_users_multiplier(data).then((r) => {
          setLoading(false);
          if (r.error === "False") {
            load_users_overview();
            setVisible(false);
            addNotification(r.message, "success");
          } else {
            addNotification(r.message, "error");
          }
        });
      }
    }
  }

  const handleStatusButtonClick = () => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
        c_id: clientData.id,
        s_id: clientData.server,
      };

      php.change_users_status(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          load_users_overview();
          if (r.on_off == "0") {
            addNotification(r.message, "error");
          } else {
            addNotification(r.message, "success");
          }
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const handleLockButtonClick = () => {
    if (clientData?.block_minute == "") {
      var selection = parseInt(window.prompt("Enter Minutes To Lock"));
      if (
        isNaN(parseFloat(selection)) ||
        parseFloat(selection) == null ||
        parseFloat(selection) == "" ||
        parseFloat(selection) == undefined
      ) {
        window.alert("Only Number Are Allowed");
      } else {
        if (!loading) {
          setLoading(true);
          let data = {
            sr: localStorage.getItem("server"),
            jwt: localStorage.getItem("token"),
            g_id: localStorage.getItem("godsId"),
            c_id: clientData.id,
            s_id: clientData.server,
            lock_time: selection,
          };

          php.add_users_lock(data).then((r) => {
            setLoading(false);
            if (r.error === "False") {
              load_users_overview();
              if (r.on_off == "0") {
                addNotification(r.message, "error");
              } else {
                addNotification(r.message, "success");
              }
            } else {
              addNotification(r.message, "error");
            }
          });
        }
      }
    } else {
      let check = window.confirm(
        "Are you sure want to unlock trades for client ?"
      );
      if (check) {
        if (!loading) {
          setLoading(true);
          let data = {
            sr: localStorage.getItem("server"),
            jwt: localStorage.getItem("token"),
            g_id: localStorage.getItem("godsId"),
            c_id: clientData.id,
            s_id: clientData.server,
          };

          php.cancel_users_lock(data).then((r) => {
            setLoading(false);
            if (r.error === "False") {
              load_users_overview();
              if (r.on_off == "0") {
                addNotification(r.message, "error");
              } else {
                addNotification(r.message, "success");
              }
            } else {
              addNotification(r.message, "error");
            }
          });
        }
      }
    }
  };

  const handleFatakButtonClick = () => {
    if (clientData?.fatak <= 0) {
      var selection = parseInt(
        window.prompt("Enter Limit For Boundary To Trigger")
      );
      if (
        isNaN(parseFloat(selection)) ||
        parseFloat(selection) == null ||
        parseFloat(selection) == "" ||
        parseFloat(selection) == undefined
      ) {
        window.alert("Only Number Are Allowed");
      } else {
        if (!loading) {
          setLoading(true);
          let data = {
            sr: localStorage.getItem("server"),
            jwt: localStorage.getItem("token"),
            g_id: localStorage.getItem("godsId"),
            c_id: clientData.id,
            s_id: clientData.server,
            fatak: selection,
          };

          php.add_users_fatak(data).then((r) => {
            setLoading(false);
            if (r.error === "False") {
              load_users_overview();
              if (r.on_off == "0") {
                addNotification(r.message, "error");
              } else {
                addNotification(r.message, "success");
              }
            } else {
              addNotification(r.message, "error");
            }
          });
        }
      }
    } else {
      let check = window.confirm(
        "Are you sure want to Reset client Boundary ?"
      );
      if (check) {
        if (!loading) {
          setLoading(true);
          let data = {
            sr: localStorage.getItem("server"),
            jwt: localStorage.getItem("token"),
            g_id: localStorage.getItem("godsId"),
            c_id: clientData.id,
            s_id: clientData.server,
          };

          php.cancel_users_fatak(data).then((r) => {
            setLoading(false);
            if (r.error === "False") {
              load_users_overview();
              if (r.on_off == "0") {
                addNotification(r.message, "error");
              } else {
                addNotification(r.message, "success");
              }
            } else {
              addNotification(r.message, "error");
            }
          });
        }
      }
    }
  };

  const [visible, setVisible] = useState(false);
  const [visibleDepositModal, setVisibleDepositModal] = useState(false);
  const [visibleLimitModal, setVisibleLimitModal] = useState(false);
  const [visibleLiveSummaryModal, setVisibleLiveSummaryModal] = useState(false);
  const [position, setPosition] = useState("top");

  const show = (position) => {
    setPosition("top");
    setVisible(true);
  };

  const showDepositModal = (position) => {
    setPosition("top");
    setVisibleDepositModal(true);
  };

  const showLimitModal = (position) => {
    setPosition("top");
    setVisibleLimitModal(true);
  };

  const showLiveSummaryModal = (position) => {
    setPosition("top");
    setVisibleLiveSummaryModal(true);
  };
  const Row = ({ label, value, color, percentSign }) => (
    <div style={{ display: "flex" }}>
      <div style={{ width: "70px" }}>
        <BoldText>{label}</BoldText>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: "5px",
          marginRight: "5px",
        }}
      >
        <FontAwesomeIcon color={color} icon={faArrowAltCircleRight} />
      </div>
      <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
        <BoldText>
          {value == undefined ? 0 : parseFloat(value)}
          {percentSign ? "%" : ""}
        </BoldText>
      </div>
    </div>
  );

  return (
    <>
      <Wrapper>
        <FlexContainer>
          <FlexItem>
            <GrayText>Client Name</GrayText>
            <BoldText>
              <span style={{ color: "gray" }}>
                {clientData?.alias == null || clientData?.alias == ""
                  ? ""
                  : clientData?.alias + " | "}
              </span>
              {clientData?.name}
            </BoldText>
          </FlexItem>
          <FlexItem>
            <GrayText>Client Username</GrayText>
            <BlueText>
              {clientData?.username}
              <span style={{ fontSize: 12, color: "black" }}>
                {" (" +
                  moment(clientData?.date_created).format("DD-MMM-YY") +
                  ")"}
              </span>
            </BlueText>
          </FlexItem>
          <FlexItem>
            <GrayText>Weekly Profit Loss</GrayText>
            <GreenText
              style={{
                color:
                  clientData?.weekly_pl >= 0
                    ? NewTheme.greencolor
                    : NewTheme.redcolor,
              }}
            >
              <NumberFormatIn value={parseFloat(clientData?.weekly_pl)} />
            </GreenText>
          </FlexItem>
        </FlexContainer>
        <FlexContainer>
          <FlexItem>
            <GrayText>Balance</GrayText>
            <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
              <BlackText>{clientData?.deposit}</BlackText>
              <Button
                label="Edit"
                outlined
                aria-label="Filter"
                size="small"
                style={{
                  padding: 0,
                  height: "25px",
                  width: "40px",
                }}
                onClick={showDepositModal}
              />
            </div>
          </FlexItem>
          <FlexItem>
            <GrayText>Mobile</GrayText>
            <BlackText>{clientData?.mobile}</BlackText>
          </FlexItem>
          <FlexItem>
            {clientData?.acc_type == "2" ? (
              <GrayText>
                Admin{" "}
                <span style={{ color: "black", fontWeight: "bold" }}>
                  {clientData?.master}
                </span>
              </GrayText>
            ) : (
              <GrayText>
                Master{" "}
                <span style={{ color: "black", fontWeight: "bold" }}>
                  {clientData?.master}
                  <Button
                    icon="pi pi-times"
                    outlined
                    aria-label="Filter"
                    size="small"
                    style={{
                      padding: 0,
                      marginLeft: 10,
                      height: "20px",
                      width: "20px",
                    }}
                    onClick={() => removeMaster()}
                  />
                </span>
              </GrayText>
            )}
            <GrayText>
              Server{" "}
              <span style={{ color: "black", fontWeight: "bold" }}>
                {clientData?.server?.toUpperCase()}
              </span>
            </GrayText>
          </FlexItem>
        </FlexContainer>
        <FlexContainer>
          <FlexItem>
            <GrayText>
              Multiplier
              <Button
                label="Edit"
                outlined
                aria-label="Filter"
                size="small"
                style={{
                  padding: 0,
                  marginLeft: 5,
                  height: "20px",
                  width: "40px",
                }}
                onClick={() => show()}
              />
            </GrayText>
            <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Row
                  color={"#4cd07d"}
                  label="Fut"
                  value={clientData?.fut_multiplier}
                />
                <Row
                  color={"#4cd07d"}
                  label="Mcx"
                  value={clientData?.mcx_multiplier}
                />
                <Row
                  color={"#4cd07d"}
                  label="Option"
                  value={clientData?.fo_multiplier}
                />
              </div>
              <div
                style={{
                  borderLeft: "1px solid gray",
                  paddingLeft: 10,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Row
                  color={"#4cd07d"}
                  label="Forex"
                  value={clientData?.forex_multiplier}
                />
                <Row
                  color={"#4cd07d"}
                  label="US.Stocks"
                  value={clientData?.stocks_multiplier}
                />
                <Row
                  color={"#4cd07d"}
                  label="Crypto"
                  value={clientData?.crypto_multiplier}
                />
              </div>
            </div>
          </FlexItem>
          <FlexItem>
            <GrayText>Brokerages</GrayText>
            <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Row
                  color={"#9272e1"}
                  label="Fut"
                  value={clientData?.fut_brokerage}
                />
                <Row
                  color={"#9272e1"}
                  label="Mcx"
                  value={clientData?.mcx_brokerage}
                />
                <Row
                  color={"#9272e1"}
                  label="Option"
                  value={clientData?.fo_brokerage}
                />
              </div>
              <div
                style={{
                  borderLeft: "1px solid gray",
                  paddingLeft: 10,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Row
                  color={"#9272e1"}
                  label="Forex"
                  value={clientData?.forex_brokerage}
                />
                <Row
                  color={"#9272e1"}
                  label="US.Stocks"
                  value={clientData?.stocks_brokerage}
                />
                <Row
                  color={"#9272e1"}
                  label="Crypto"
                  value={clientData?.crypto_brokerage}
                />
              </div>
            </div>
          </FlexItem>
          <FlexItem>
            <GrayText>
              Broker:{" "}
              <span style={{ color: NewTheme.MainColor, fontWeight: "bold" }}>
                {clientData.broker_id > 0 ? clientData.broker_username : "----"}
              </span>
              {" | Sharing : "}
              <span style={{ color: NewTheme.MainColor, fontWeight: "bold" }}>
                {clientData.broker_id > 0 ? clientData.sharing : "0"}%
              </span>
            </GrayText>

            <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Row
                  color={"#f2a15b"}
                  percentSign
                  label="Fut"
                  value={clientData?.fut_percent}
                />
                <Row
                  color={"#f2a15b"}
                  percentSign
                  label="Mcx"
                  value={clientData?.mcx_percent}
                />
                <Row
                  color={"#f2a15b"}
                  percentSign
                  label="Option"
                  value={clientData?.fo_percent}
                />
              </div>
              <div
                style={{
                  borderLeft: "1px solid gray",
                  paddingLeft: 10,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Row
                  color={"#f2a15b"}
                  percentSign
                  label="Forex"
                  value={clientData?.forex_percent}
                />
                <Row
                  color={"#f2a15b"}
                  percentSign
                  label="US.Stocks"
                  value={clientData?.stocks_percent}
                />
                <Row
                  color={"#f2a15b"}
                  percentSign
                  label="Crypto"
                  value={clientData?.crypto_percent}
                />
              </div>
            </div>
          </FlexItem>
        </FlexContainer>
      </Wrapper>

      <ButtonGrid>
        <CreateClientButton
          active={true}
          color={NewTheme.MainColor}
          onClick={() => showLiveSummaryModal()}
        >
          {"LIVE SUMMARY"}
        </CreateClientButton>
        <CreateClientButton
          active={clientData?.status == 1 ? true : false}
          color={"#22c55e"}
          onClick={() => handleStatusButtonClick()}
        >
          {"ACTIVE"}
        </CreateClientButton>
        <CreateClientButton
          active={true}
          color={"#22c55e"}
          onClick={() => handleLockButtonClick()}
        >
          {"LOCK " +
            (clientData?.block_minute > 0
              ? "(" + clientData?.block_minute + "m)"
              : "")}
        </CreateClientButton>
        <CreateClientButton
          active={true}
          color={"#22c55e"}
          onClick={() => handleFatakButtonClick()}
        >
          {"BOUNDARY " +
            (clientData?.fatak != "" ? "(" + clientData?.fatak + ")" : "")}
        </CreateClientButton>
        {buttonsData.map((button, index) => (
          <CreateClientButton
            key={index}
            active={button.active}
            color={button.color}
            onClick={() => handleButtonClick(button.id)} // Add the onClick handler
          >
            {button.text}
          </CreateClientButton>
        ))}
      </ButtonGrid>

      {visibleDepositModal ? (
        <DepositModal
          key={clientData?.id}
          setVisible={() => setVisibleDepositModal(false)}
          position={position}
          id={clientData?.id}
          deposit={clientData?.deposit}
          visible={visibleDepositModal}
          reload={() => load_users_overview()}
        />
      ) : null}

      {visibleLimitModal ? (
        <LimitModal
          key={clientData?.id}
          setVisible={() => setVisibleLimitModal(false)}
          position={position}
          id={clientData?.id}
          visible={visibleLimitModal}
          reload={() => load_users_overview()}
        />
      ) : null}

      {visible ? (
        <MultiplierModal
          key={clientData?.id}
          setVisible={() => setVisible(false)}
          position={position}
          visible={visible}
          max_multiplier={clientData?.max_multiplier}
          min_multiplier={clientData?.min_multiplier}
          fut={clientData?.fut_multiplier}
          mcx={clientData?.mcx_multiplier}
          fo={clientData?.fo_multiplier}
          forex={clientData?.forex_multiplier}
          crypto={clientData?.crypto_multiplier}
          stocks={clientData?.stocks_multiplier}
          handleUsersMultiplier={(fut, mcx, fo, forex, crypto, stocks) =>
            handleUsersMultiplier(fut, mcx, fo, forex, crypto, stocks)
          }
        />
      ) : null}

      {visibleLiveSummaryModal ? (
        <LiveSummaryModal
          key={clientData?.id}
          usersId={clientData?.id}
          server={clientData?.server}
          setVisible={() => setVisibleLiveSummaryModal(false)}
          position={position}
          visible={visibleLiveSummaryModal}
          //
          fut_multiplier={clientData?.fut_multiplier}
          mcx_multiplier={clientData?.mcx_multiplier}
          fo_multiplier={clientData?.fo_multiplier}
          forex_multiplier={clientData?.forex_multiplier}
          crypto_multiplier={clientData?.crypto_multiplier}
          stocks_multiplier={clientData?.stocks_multiplier}
          //
          deposit={clientData?.deposit}
          blocked_limit={clientData?.blocked_limit}
          weekly_pl={clientData?.weekly_pl}
          clientUsername={clientData?.username}
        />
      ) : null}
    </>
  );
}
