import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";

import { InputText } from "primereact/inputtext";
import Php from "../../Backend/Php";
import { useNotification } from "../Notification/NotificationContext";
import { FilterMatchMode } from "primereact/api";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { NewTheme } from "../Theme/Theme";
import moment from "moment/moment";
import { Calendar } from "primereact/calendar";
import TopbarMost from "../Common/TopbarMost";
import Filter from "./Filter/Filter";
import "jspdf-autotable";
import jsPDF from "jspdf";
import NumberFormatIn from "../Common/NumberFormatIn";
import * as ExcelJS from "exceljs";
import { Message } from "primereact/message";
import { ProgressSpinner } from "primereact/progressspinner";

const php = new Php();

const height = window.innerHeight;

export default function DailyFTD() {
  const [pendingData, setPendingData] = useState([]);
  const [pendingData2, setPendingData2] = useState([]);
  const [errorMessage, setErrorMessage] = useState(
    "Please select from filter and click submit"
  );

  const [depositTotal, setDepositTotal] = useState(0);
  const [withdrawTotal, setWithdrawTotal] = useState(0);
  const [brokerageTotal, setBrokerageTotal] = useState(0);
  const [profitLossTotal, setProfitLossTotal] = useState(0);
  const [joinedToday, setJoinedToday] = useState(0);
  const [ftdToday, setFTDToday] = useState(0);

  const { addNotification } = useNotification();
  const [loading, setLoading] = React.useState(false);

  const [date, setDate] = useState(new Date());

  ///filters
  const [adminsFilter, setAdminsFilter] = React.useState([]);
  const [selectedAdmin, setSelectedAdmin] = React.useState([]);

  const [mastersFilter, setMastersFilter] = React.useState([]);
  const [selectedMaster, setSelectedMaster] = React.useState([]);

  const [filters, setFilters] = useState({
    symbol: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  const [filtersGlobal, setFiltersGlobal] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const value = filtersGlobal["global"] ? filtersGlobal["global"].value : "";
  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filtersGlobal };

    _filters["global"].value = value;

    setFilters(_filters);
  };

  const [totalRecords, setTotalRecords] = useState(0);
  const [pagination, setPagination] = useState({ first: 0, rows: 15 });

  const [sortField, setSortField] = useState("id"); // Default to 'id' field
  const [sortOrder, setSortOrder] = useState(1);

  React.useEffect(() => {
    load_ftd_filter();
  }, []);

  const load_ftd_filter = () => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
      };

      php.load_ftd_filter(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          setAdminsFilter(r.admins);
          setMastersFilter(r.masters);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const clear_filter = () => {
    setSelectedAdmin({});
    setSelectedMaster({});
    setDate(new Date());
    setDepositTotal(0);
    setWithdrawTotal(0);
    setProfitLossTotal(0);
    setBrokerageTotal(0);
    setErrorMessage(
      "Filter cleared. Please select from filter and click submit"
    );
    setPendingData([]);
    setPendingData2([]);
  };

  const load_daily_ftd = (
    e,
    pagination = { first: 0, rows: 15 },
    sorting = { sort_field: "id", sort_order: "DESC" }
  ) => {
    let admin_id = selectedAdmin?.id;
    let master_id = selectedMaster?.id;

    if (e == "clear") {
      clear_filter();
      return;
    }
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
        from_date:
          e == "clear"
            ? moment(new Date()).format("YYYY-MM-DD 00:00:00")
            : moment(date).format("YYYY-MM-DD 00:00:00"),
        to_date:
          e == "clear"
            ? moment(new Date()).format("YYYY-MM-DD 23:59:59")
            : moment(date).format("YYYY-MM-DD 23:59:59"),
        m_id: master_id <= 0 || e == "clear" ? 0 : master_id,
        a_id: admin_id <= 0 || e == "clear" ? 0 : admin_id,
        first: e == "clear" ? 0 : pagination.first, // Page offset
        rows: e == "clear" ? 15 : pagination.rows, // Number of rows per page
        sort_field: e == "clear" ? "id" : sorting.sort_field, // Sorting field
        sort_order: e == "clear" ? "DESC" : sorting.sort_order, // Sorting order (ASC or DESC)
      };

      php.load_daily_ftd(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          setMastersFilter(r.masters);

          setPendingData(r.data);
          setPendingData2(r.data);
          if (pagination.first == 0) {
            setTotalRecords(r.totalRecords);
          }

          setDepositTotal(r.total_deposit);
          setWithdrawTotal(r.total_withdraw);
          setProfitLossTotal(r.total_profit_loss);
          setBrokerageTotal(r.total_brokerage);
          setJoinedToday(r.joined_today);
          setFTDToday(r.ftd_today);
        } else {
          setErrorMessage("No Records Found");
          addNotification(r.message, "error");
        }
      });
    }
  };
  const Filter = (x) => {
    if (x == "a") {
      const todayStart = new Date();
      todayStart.setHours(0, 0, 0, 0);

      const newdata = pendingData2.filter(
        (x) => new Date(x.date_created) > todayStart
      );
      setPendingData(newdata);
    }

    if (x == "b") {
      const newdata = pendingData2.filter((x) => x.total_deposit > 0);
      setPendingData(newdata);
    }
  };

  const stats = [
    {
      label: "New Users",
      value: joinedToday,
      color: NewTheme.MainColor,
      onClick: () => Filter("a"),
    },
    {
      label: "New FTD",
      value: ftdToday,
      color: NewTheme.MainColor,
      onClick: () => Filter("b"),
    },
    {
      label: "Deposit",
      value: depositTotal,
      colorOnProps: true,
    },
    {
      label: "Withdrawal",
      value: withdrawTotal,
      colorOnProps: true,
    },
    {
      label: "Brokerage",
      value: brokerageTotal,
      color: NewTheme.MainColor,
    },
    {
      label: "Profit/Loss",
      value: profitLossTotal,
      colorOnProps: true,
    },
  ];

  const StatItem = ({ label, value, color, colorOnProps, onClick }) => (
    <div
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: 30,
        flexDirection: "column",
      }}
      onClick={onClick}
    >
      <div style={{ fontSize: 12 }}>{label}</div>
      <div style={{ fontSize: 18, color }}>
        <NumberFormatIn value={value} fixed={0} colorOnValue={colorOnProps} />
      </div>
    </div>
  );

  const filterbar = (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: 20,
        fontWeight: "bold",
      }}
    >
      {stats.map((stat, index) => (
        <StatItem
          key={index}
          label={stat.label}
          value={stat.value}
          color={stat.color}
          onClick={stat.onClick}
          colorOnProps={stat.colorOnProps}
        />
      ))}
    </div>
  );

  const exportExcel = () => {
    // Create a new Excel workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("data");

    // Specify the actual columns you want to export
    const colsToExport = [
      "server",
      "master",
      "username",
      "mobile",
      "date_created",
      "total_transaction",
      "first_transaction",
      "last_transaction",
      "total_deposit",
      "total_withdraw",
      "total_pl",
      "diff",
      "total_brokerage",
    ];

    // Specify the display names for the headers
    const headerDisplayNames = [
      "Server",
      "Master",
      "Username",
      "Mobile",
      "Date",
      "Total Transaction",
      "First Transaction",
      "Last Transaction",
      "Total Deposit",
      "Total Withdraw",
      "Total Profit/Loss",
      "Difference",
      "Total Brokerage",
    ];

    const headerRow = worksheet
      .addRow(["First Time Deposit (FTD) Report By Upperline"])
      .commit();
    worksheet.mergeCells(`A${worksheet.rowCount}:L${worksheet.rowCount}`);
    const mergedRange = worksheet.getCell(
      `A${worksheet.rowCount}:O${worksheet.rowCount}`
    );
    mergedRange.alignment = { horizontal: "center" };
    mergedRange.font = { bold: true, size: 16 };

    worksheet
      .addRow([1, "Total Profit/Loss", parseFloat(profitLossTotal)])
      .eachCell((cell, colNumber) => {
        // Apply color to font based on the "totalProfitLoss" condition
        if (colNumber === 3 && !isNaN(profitLossTotal)) {
          const color =
            profitLossTotal > 0
              ? NewTheme.greencolorExcel
              : NewTheme.redcolorExcel; // Green for positive, Red for negative

          cell.font = {
            color: { argb: color },
          };

          // Convert totalProfitLoss to number and set number format
          cell.value = Number(profitLossTotal);
          cell.numFmt = "#,##0.00"; // Adjust the number format as needed
        }
      });
    worksheet
      .addRow([2, "Total Brokerage", parseFloat(brokerageTotal)])
      .eachCell((cell, colNumber) => {
        // Apply color to font based on the "totalProfitLoss" condition
        if (colNumber === 3 && !isNaN(brokerageTotal)) {
          const color =
            brokerageTotal > 0
              ? NewTheme.greencolorExcel
              : NewTheme.redcolorExcel; // Green for positive, Red for negative

          cell.font = {
            color: { argb: color },
          };

          // Convert totalProfitLoss to number and set number format
          cell.value = Number(brokerageTotal);
          cell.numFmt = "#,##0.00"; // Adjust the number format as needed
        }
      });
    worksheet
      .addRow([3, "Total Deposit", parseFloat(depositTotal)])
      .eachCell((cell, colNumber) => {
        // Apply color to font based on the "totalProfitLoss" condition
        if (colNumber === 3 && !isNaN(depositTotal)) {
          const color =
            depositTotal > 0
              ? NewTheme.greencolorExcel
              : NewTheme.redcolorExcel; // Green for positive, Red for negative

          cell.font = {
            color: { argb: color },
          };

          // Convert totalProfitLoss to number and set number format
          cell.value = Number(depositTotal);
          cell.numFmt = "#,##0.00"; // Adjust the number format as needed
        }
      });
    worksheet
      .addRow([4, "Total Withdraw", parseFloat(withdrawTotal)])
      .eachCell((cell, colNumber) => {
        // Apply color to font based on the "totalProfitLoss" condition
        if (colNumber === 3 && !isNaN(withdrawTotal)) {
          const color =
            withdrawTotal > 0
              ? NewTheme.greencolorExcel
              : NewTheme.redcolorExcel; // Green for positive, Red for negative

          cell.font = {
            color: { argb: color },
          };

          // Convert totalProfitLoss to number and set number format
          cell.value = Number(withdrawTotal);
          cell.numFmt = "#,##0.00"; // Adjust the number format as needed
        }
      });

    // Add empty rows between summary and data
    for (let i = 0; i < 2; i++) {
      worksheet.addRow([]);
    }

    // Add column headers to the worksheet with display names
    const headers = worksheet.addRow(headerDisplayNames);

    headers.eachCell((cell) => {
      cell.font = {
        bold: true,
      };
    });

    // Extract only the desired columns from transactionData
    const filteredData = pendingData.map((item) => {
      return colsToExport.reduce((acc, col) => {
        if (col === "buy_sell") {
          acc[col] = item[col] == 0 ? "Buy" : "Sell";
        } else {
          acc[col] = item[col];
        }
        return acc;
      }, {});
    });

    // Add the filtered data to the worksheet
    filteredData.forEach((data) => {
      worksheet.addRow(Object.values(data));
    });

    // Set column width to 16 for all columns
    worksheet.columns.forEach((column) => {
      column.width = 16;
    });

    const profitLossIndex = colsToExport.indexOf("total_pl");
    const brokerageIndex = colsToExport.indexOf("total_brokerage");
    const depositIndex = colsToExport.indexOf("total_deposit");
    const withdrawIndex = colsToExport.indexOf("total_withdraw");
    const diffIndex = colsToExport.indexOf("diff");
    const transactionIndex = colsToExport.indexOf("total_transaction");

    worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
      const cellValue = row.getCell(profitLossIndex + 1).value;
      const brokerageValue = row.getCell(brokerageIndex + 1).value;
      const depositValue = row.getCell(depositIndex + 1).value;
      const withdrawValue = row.getCell(withdrawIndex + 1).value;
      const diffValue = row.getCell(diffIndex + 1).value;
      const transactionValue = row.getCell(transactionIndex + 1).value;
      // Format as numbers with two decimal places
      const formatAsNumber = (cell, value) => {
        if (value !== null && !isNaN(value) && rowNumber > 4) {
          cell.value = rowNumber > 10 ? Number(value) : value;
          cell.numFmt = rowNumber > 10 ? "#,##0.00" : undefined; // Adjust the number format as needed
        }
      };

      formatAsNumber(row.getCell(profitLossIndex + 1), cellValue);
      formatAsNumber(row.getCell(brokerageIndex + 1), brokerageValue);
      formatAsNumber(row.getCell(depositIndex + 1), depositValue);
      formatAsNumber(row.getCell(withdrawIndex + 1), withdrawValue);
      formatAsNumber(row.getCell(diffIndex + 1), diffValue);
      formatAsNumber(row.getCell(transactionIndex + 1), transactionValue);

      if (cellValue !== null && !isNaN(cellValue) && rowNumber > 4) {
        const color =
          cellValue > 0 ? NewTheme.greencolorExcel : NewTheme.redcolorExcel; // Green for positive, Red for negative

        row.getCell(profitLossIndex + 1).font = {
          color: { argb: color },
        };
      }
      if (depositValue !== null && !isNaN(depositValue) && rowNumber > 4) {
        const depositColor =
          depositValue > 0 ? NewTheme.greencolorExcel : NewTheme.redcolorExcel; // Green for positive, Red for negative

        row.getCell(depositIndex + 1).font = {
          color: { argb: depositColor },
        };
      }
      if (withdrawValue !== null && !isNaN(withdrawValue) && rowNumber > 4) {
        const withdrawColor =
          withdrawValue > 0 ? NewTheme.greencolorExcel : NewTheme.redcolorExcel; // Green for positive, Red for negative

        row.getCell(withdrawIndex + 1).font = {
          color: { argb: withdrawColor },
        };
      }
      if (diffValue !== null && !isNaN(diffValue) && rowNumber > 4) {
        const diffColor =
          diffValue > 0 ? NewTheme.greencolorExcel : NewTheme.redcolorExcel; // Green for positive, Red for negative

        row.getCell(diffIndex + 1).font = {
          color: { argb: diffColor },
        };
      }
      if (brokerageValue !== null && !isNaN(brokerageValue) && rowNumber > 4) {
        const brokerageColor =
          brokerageValue > 0
            ? NewTheme.greencolorExcel
            : NewTheme.redcolorExcel; // Green for positive, Red for negative

        row.getCell(brokerageIndex + 1).font = {
          color: { argb: brokerageColor },
        };
      }
      if (
        transactionValue !== null &&
        !isNaN(transactionValue) &&
        rowNumber > 4
      ) {
        const transactionColor =
          transactionValue == 0 ? NewTheme.redcolorExcel : "#000000"; // Green for positive, Red for negative
        row.getCell(transactionIndex + 1).font = {
          color: { argb: transactionColor },
        };
      }
    });

    // Add empty rows between data and footer
    for (let i = 0; i < 2; i++) {
      worksheet.addRow([]);
    }

    var fileName = "First_Time_Deposit_FTD_Report_By_Upperline_";

    // Create Excel file and trigger download
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAsExcelFile(buffer, fileName);
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const searchBar = (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        paddingRight: 20,
        fontWeight: "bold",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 10,
            minWidth: 200,
          }}
        >
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 20,
            }}
          >
            <Dropdown
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minWidth: 200,
                height: 30,
              }}
              showClear
              onChange={(e) => {
                if (e.value == undefined) {
                  setSelectedAdmin({});
                } else {
                  setSelectedAdmin(e.value);
                }
              }}
              value={selectedAdmin}
              options={adminsFilter}
              optionLabel="username"
              placeholder="Select Admin"
              filter
            />
          </div>

          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 10,
            }}
          >
            <Dropdown
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minWidth: 200,
                height: 30,
              }}
              showClear
              onChange={(e) => {
                if (e.value == undefined) {
                  setSelectedMaster({});
                } else {
                  setSelectedMaster(e.value);
                }
              }}
              value={selectedMaster}
              options={mastersFilter}
              optionLabel="username"
              placeholder="Select Master"
              filter
            />
          </div>
        </div>
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <Calendar
          dateFormat="dd/mm/yy"
          style={{ width: 140 }}
          selectionMode="single"
          id="calendar-12h"
          value={date}
          onChange={(e) => setDate(e.value)}
        />
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <InputText
          style={{ width: 140, height: 30 }}
          type="search"
          value={value || ""}
          onChange={(e) => onGlobalFilterChange(e)}
          placeholder="Search"
        />
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 10,
        }}
      >
        <Button
          type="button"
          // icon="pi pi-filter"
          severity="success"
          label="SUBMIT"
          style={{
            height: 25,
            width: 100,
            fontSize: 12,
          }}
          onClick={() => load_daily_ftd()}
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          // icon="pi pi-times"
          label="CLEAR"
          severity="danger"
          style={{
            height: 25,
            width: 100,
            fontSize: 12,
          }}
          onClick={() => load_daily_ftd("clear")}
          data-pr-tooltip="XLS"
        />
        <Button
          type="button"
          label="XLS"
          severity="primary"
          style={{
            height: 25,
            width: 100,
            fontSize: 12,
          }}
          onClick={exportExcel}
          data-pr-tooltip="EXCEL"
        />
      </div>
    </div>
  );

  const onSort = (e) => {
    const newSortField = e.sortField;
    const newSortOrder = e.sortOrder; // 1 for ascending, -1 for descending
    const sortOrder = newSortOrder === 1 ? "ASC" : "DESC";

    setSortField(newSortField);
    setSortOrder(newSortOrder);
    setPagination({ first: 0, rows: pagination.rows });

    // Fetch the sorted data
    load_daily_ftd(null, pagination, {
      sort_field: newSortField,
      sort_order: sortOrder,
    });
  };

  return (
    <div
      className="card"
      style={{
        padding: "0.5rem",
        paddingTop: 0,
        borderRadius: "10px",
        overflow: "hidden",
      }}
    >
      <TopbarMost name="First Time Deposit (FTD) " cmp={filterbar} />
      <TopbarMost
        height={45}
        background={"#ffffff"}
        noSidebar
        cmp={searchBar}
      />

      <div id={"stick"} style={{ position: "relative" }}>
        {/* <DataTable
          removableSort
          stripedRows
          showGridlines
          scrollable
          scrollHeight={height - 195}
          size="small"
          filters={filters}
          // filterDisplay="row"
          value={pendingData}
          style={{ fontWeight: "bold", fontSize: 14, borderColor: "red" }}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          // globalFilterFields={["username", "symbol"]}
          loading={loading}
          lazy
          rows={15}
          // rows={pagination.rows}
          // first={pagination.first}
          // sortField={sortField} // Pass current sortField
          // sortOrder={sortOrder} // Pass current sortOrder
          // totalRecords={totalRecords}
          paginator
          paginatorTemplate={{
            layout:
              "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown TotalRecords",

            // CurrentPageReport: (options) => {
            //   return (
            //     <span
            //       style={{
            //         color: "var(--text-color)",
            //         userSelect: "none",
            //         width: "120px",
            //         textAlign: "center",
            //       }}
            //     >
            //       {options.first} - {options.last} of {options.totalRecords}
            //     </span>
            //   );
            // },
          }}
          onSort={onSort}
          onPage={(e) => {
            setPagination({ first: e.first, rows: e.rows });
            load_daily_ftd(
              null,
              { first: e.first, rows: e.rows },
              { sort_field: sortField, sort_order: sortOrder }
            ); // Fetch new data on page change
          }}
          rowsPerPageOptions={[10, 25, 50, 100]}
          onFilter={(e) => setFilters(e.filters)}
        > */}
        {loading ? (
          <div className="card flex justify-content-center">
            <ProgressSpinner style={{ width: "50px", height: "50px" }} />
          </div>
        ) : pendingData.length > 0 ? (
          <DataTable
            removableSort
            stripedRows
            showGridlines
            scrollable
            scrollHeight={height - 195}
            size="small"
            filters={filters}
            // filterDisplay="row"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            value={!loading && pendingData}
            style={{ fontWeight: "bold", fontSize: 14 }}
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            globalFilterFields={["username", "symbol"]}
            paginator
            rows={15}
            loading={loading}
            rowsPerPageOptions={[10, 15, 30, 50, 100]}
          >
            <Column
              field="server"
              showFilterMenu={false}
              header="#"
              body={(row, index) => {
                return (
                  <div style={{ color: NewTheme.MainColor }}>
                    {index.rowIndex + 1}
                  </div>
                );
              }}
            />
            <Column field="server" showFilterMenu={false} header="Server" />
            <Column
              field="master"
              showFilterMenu={false}
              header="Master"
              sortable
            />
            <Column
              field="username"
              showFilterMenu={false}
              header="Client"
              sortable
              body={(row) => {
                return (
                  <div style={{ color: NewTheme.MainColor }}>
                    {row.username}
                    <span style={{ fontSize: 12, color: "black" }}>
                      {" (" +
                        Math.abs(
                          moment
                            .duration(moment(row.date_created).diff(moment()))
                            .asDays()
                        ).toFixed(0) +
                        " days ago)"}
                    </span>
                    <br />
                    <span style={{ fontSize: 10, color: "black" }}>
                      {moment(row.date_created).format("DD-MMM-YYYY")}
                    </span>
                  </div>
                );
              }}
            />
            <Column
              field="total_transaction"
              showFilterMenu={false}
              header="Transactions"
              sortable
              body={(row) => {
                return (
                  <div style={{ color: NewTheme.MainColor }}>
                    {"Total : " + row.total_transaction}
                    <br />
                    {row.first_transaction != null ? (
                      <>
                        <span style={{ fontSize: 10, color: "black" }}>
                          {"First : " +
                            moment(row.first_transaction).format("DD-MMM-YYYY")}
                        </span>
                        <br />
                        <span style={{ fontSize: 10, color: "black" }}>
                          {"Last : " +
                            moment(row.last_transaction).format("DD-MMM-YYYY")}
                        </span>
                      </>
                    ) : (
                      <span style={{ fontSize: 10, color: NewTheme.redcolor }}>
                        No transactions
                      </span>
                    )}
                  </div>
                );
              }}
            />
            <Column
              field="mobile"
              showFilterMenu={false}
              header="Mobile"
              sortable
              body={(row) => {
                return row.mobile != "null" ? row.mobile : "-";
              }}
            />
            <Column
              field="total_deposit"
              showFilterMenu={false}
              header="Total Deposit"
              sortable
              body={(row) => {
                return (
                  <NumberFormatIn
                    value={row.total_deposit}
                    fixed={0}
                    colorOnValue={true}
                  />
                );
              }}
            />
            <Column
              field="total_withdraw"
              showFilterMenu={false}
              header="Total Withdrawal"
              sortable
              body={(row) => {
                return (
                  <NumberFormatIn
                    value={row.total_withdraw}
                    fixed={0}
                    colorOnValue={true}
                  />
                );
              }}
            />
            <Column
              field="total_pl"
              showFilterMenu={false}
              header="Total Profit"
              sortable
              body={(row) => {
                return (
                  <NumberFormatIn
                    value={row.total_pl}
                    fixed={0}
                    colorOnValue={true}
                  />
                );
              }}
            />
            <Column
              field="diff"
              showFilterMenu={false}
              header="Diff (%)"
              sortable
              body={(row) => {
                return (
                  <NumberFormatIn
                    value={row.diff}
                    fixed={2}
                    showDecimalFixed={2}
                    colorOnValue={true}
                  />
                );
              }}
            />
            <Column
              field="total_brokerage"
              showFilterMenu={false}
              header="Total Brokerage"
              sortable
              body={(row) => {
                return (
                  <NumberFormatIn
                    value={row.total_brokerage}
                    fixed={0}
                    colorOnValue={true}
                  />
                );
              }}
            />
          </DataTable>
        ) : (
          <div className="card flex justify-content-center">
            <Message
              style={{ marginTop: 30 }}
              severity={
                errorMessage.toLowerCase().includes("clear") ? "error" : "info"
              }
              text={errorMessage}
              className="w-full md:w-14rem"
            />
          </div>
        )}
      </div>
    </div>
  );
}
