import { Button } from "primereact/button";
import React, { useState } from "react";
import Php from "../../../Backend/Php";
import { useNotification } from "../../Notification/NotificationContext";
import { NewTheme } from "../../Theme/Theme";
import LiveSummaryModal from "./LiveSummaryModal";
import MultiplierModal from "./MultiplierModal";
import {
  BlackText,
  BlueText,
  BoldText,
  ButtonGrid,
  CreateClientButton,
  FlexContainer,
  FlexItem,
  GrayText,
  GreenText,
  Wrapper,
} from "./Styled";
import { InputText } from "primereact/inputtext";
import NumberFormatIn from "../../Common/NumberFormatIn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import CFMarginModal from "./CFMarginModal";
import SegmentPendingModal from "./SegmentPendingModal";

const php = new Php();

export default function Overview(props) {
  const [loading, setLoading] = React.useState(false);
  const [buttonsData, setButtonsData] = React.useState([]);
  const [clientData, setClientData] = React.useState([]);
  const { addNotification } = useNotification();
  const [timeDiff, setTimeDiff] = useState(0);

  React.useEffect(() => {
    load_admin_overview();
  }, [props.data.id]);

  const load_admin_overview = () => {
    if (!loading) {
      setLoading(true);
      let data2 = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
        a_id: props.data.id,
      };

      php.load_admin_overview(data2).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          setClientData(r.admins_overview[0]);
          setButtonsData(r.buttons_data);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  function handleButtonClick(key) {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
        a_id: clientData.id,
        button_key: key,
      };

      php.change_admins_setting(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          load_admin_overview();
          if (r.on_off == "0") {
            addNotification(r.message, "error");
          } else {
            addNotification(r.message, "success");
          }
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  }

  const handleDeleteTransactionButtonClick = () => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
        a_id: clientData.id,
      };

      php.change_admins_delete_transaction(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          load_admin_overview();
          if (r.on_off == "0") {
            addNotification(r.message, "error");
          } else {
            addNotification(r.message, "success");
          }
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const handleManualTradeButtonClick = () => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
        a_id: clientData.id,
      };

      php.change_admins_manual_trade(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          load_admin_overview();
          if (r.on_off == "0") {
            addNotification(r.message, "error");
          } else {
            addNotification(r.message, "success");
          }
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const handleStatusButtonClick = () => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
        a_id: clientData.id,
      };

      php.change_admins_status(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          load_admin_overview();
          if (r.on_off == "0") {
            addNotification(r.message, "error");
          } else {
            addNotification(r.message, "success");
          }
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const [visible, setVisible] = useState(false);
  const [visibleLiveSummaryModal, setVisibleLiveSummaryModal] = useState(false);
  const [visibleCfMarginModal, setVisibleCfMarginModal] = useState(false);
  const [visibleSegmentPendingModal, setVisibleSegmentPendingModal] =
    useState(false);
  const [position, setPosition] = useState("top");

  const show = (position) => {
    setPosition("top");
    setVisible(true);
  };

  const showCfMargin = (position) => {
    setPosition("top");
    setVisibleCfMarginModal(true);
  };

  const showLiveSummaryModal = (position) => {
    setPosition("top");
    setVisibleLiveSummaryModal(true);
  };

  const showSegmentPendingModal = (position) => {
    setPosition("top");
    setVisibleSegmentPendingModal(true);
  };

  const Row = ({ label, value }) => (
    <div style={{ display: "flex" }}>
      <div style={{ width: "70px" }}>
        <BoldText>{label}</BoldText>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: "5px",
          marginRight: "5px",
        }}
      >
        <FontAwesomeIcon
          color={NewTheme.MainColor}
          icon={faArrowAltCircleRight}
        />
      </div>
      <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
        <BoldText>{parseFloat(value)}</BoldText>
      </div>
    </div>
  );

  return (
    <>
      <Wrapper>
        <FlexContainer>
          <FlexItem>
            <GrayText>Admin Name</GrayText>
            <BoldText>{clientData?.name}</BoldText>
          </FlexItem>
          <FlexItem>
            <GrayText>Admin Username</GrayText>
            <BlueText>{clientData?.username}</BlueText>
          </FlexItem>
          <FlexItem>
            <GrayText>Weekly Profit Loss</GrayText>
            <GreenText
              style={{
                color:
                  clientData?.weekly_pl >= 0
                    ? NewTheme.greencolor
                    : NewTheme.redcolor,
              }}
            >
              <NumberFormatIn value={clientData?.weekly_pl} />
            </GreenText>
          </FlexItem>
        </FlexContainer>
        <FlexContainer>
          <FlexItem>
            <GrayText>
              Margin{" "}
              <span style={{ color: "black", fontWeight: "bold" }}>
                {clientData?.margin}
              </span>
            </GrayText>
            <GrayText>
              OTP{" "}
              <span style={{ color: "black", fontWeight: "bold" }}>
                {clientData?.otp}
              </span>
            </GrayText>
          </FlexItem>

          <FlexItem>
            <GrayText>
              Total Masters{" "}
              <span style={{ color: "black", fontWeight: "bold" }}>
                {clientData?.masters}
              </span>
            </GrayText>
            <GrayText>
              Total Brokers{" "}
              <span style={{ color: "black", fontWeight: "bold" }}>
                {clientData?.brokers}
              </span>
            </GrayText>
          </FlexItem>

          <FlexItem>
            <GrayText>
              Total Clients{" "}
              <span style={{ color: "black", fontWeight: "bold" }}>
                {clientData?.users}
              </span>
            </GrayText>

            <GrayText>
              Last Client{" "}
              <span style={{ color: "black", fontWeight: "bold" }}>
                {clientData?.last_login}
              </span>
            </GrayText>
          </FlexItem>
        </FlexContainer>
        <FlexContainer>
          <FlexItem>
            <GrayText>Multiplier</GrayText>
            <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <BoldText>Min : {clientData?.min_multiplier}x </BoldText>
                <BoldText> Max : {clientData?.max_multiplier}x </BoldText>
              </div>
              <Button
                label="Edit"
                outlined
                aria-label="Filter"
                size="small"
                style={{
                  padding: 0,
                  height: "25px",
                  width: "40px",
                }}
                onClick={() => show()}
              />
            </div>
          </FlexItem>

          <FlexItem>
            <GrayText>
              Segment Permission
              <Button
                label="Edit"
                outlined
                aria-label="Filter"
                size="small"
                style={{
                  padding: 0,
                  height: "20px",
                  width: "40px",
                  fontSize: 12,
                  marginLeft: 15,
                }}
                onClick={() => showSegmentPendingModal()}
              />
            </GrayText>
            <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
              {/* <div style={{ display: "flex", flexDirection: "column" }}>
                <Row label="Fut" value={clientData?.fut_cf_margin} />
                <Row label="Mcx" value={clientData?.mcx_cf_margin} />
                <Row label="Option" value={clientData?.fo_cf_margin} />
              </div>
              <div
                style={{
                  borderLeft: "1px solid gray",
                  paddingLeft: 10,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Row label="Forex" value={clientData?.forex_cf_margin} />
                <Row label="US.Stocks" value={clientData?.stocks_cf_margin} />
                <Row label="Crypto" value={clientData?.crypto_cf_margin} />
              </div> */}
            </div>
          </FlexItem>
          <FlexItem>
            <GrayText>
              Default CF Margin
              <Button
                label="Edit"
                outlined
                aria-label="Filter"
                size="small"
                style={{
                  padding: 0,
                  height: "20px",
                  width: "40px",
                  fontSize: 12,
                  marginLeft: 15,
                }}
                onClick={() => showCfMargin()}
              />
            </GrayText>
            <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Row label="Fut" value={clientData?.fut_cf_margin} />
                <Row label="Mcx" value={clientData?.mcx_cf_margin} />
                <Row label="Option" value={clientData?.fo_cf_margin} />
              </div>
              <div
                style={{
                  borderLeft: "1px solid gray",
                  paddingLeft: 10,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Row label="Forex" value={clientData?.forex_cf_margin} />
                <Row label="US.Stocks" value={clientData?.stocks_cf_margin} />
                <Row label="Crypto" value={clientData?.crypto_cf_margin} />
              </div>
            </div>
          </FlexItem>
        </FlexContainer>
      </Wrapper>

      <ButtonGrid>
        <CreateClientButton
          active={true}
          color={NewTheme.MainColor}
          onClick={() => showLiveSummaryModal()}
        >
          {"Live Summary"}
        </CreateClientButton>
        <CreateClientButton
          active={clientData?.status == 1 ? true : false}
          color={"#22c55e"}
          onClick={() => handleStatusButtonClick()}
        >
          {"ACTIVE"}
        </CreateClientButton>
        <CreateClientButton
          active={clientData?.delete_transaction == 1 ? true : false}
          color={"#a855f7"}
          onClick={() => handleDeleteTransactionButtonClick()}
        >
          {"DELETE TRANSACTION"}
        </CreateClientButton>
        <CreateClientButton
          active={clientData?.manual_trade == 1 ? true : false}
          color={"#784011de"}
          onClick={() => handleManualTradeButtonClick()}
        >
          {"MANUAL TRADE"}
        </CreateClientButton>

        {/* {buttonsData.map((button, index) => (
          <CreateClientButton
            key={index}
            active={button.active}
            color={button.color}
            onClick={() => handleButtonClick(button.id)} // Add the onClick handler
          >
            {button.text}
          </CreateClientButton>
        ))} */}
      </ButtonGrid>

      {visible ? (
        <MultiplierModal
          key={clientData?.id}
          setVisible={() => setVisible(false)}
          position={position}
          visible={visible}
          max_multiplier={clientData?.max_multiplier}
          min_multiplier={clientData?.min_multiplier}
          admin_id={props.data.id}
          load_admin_overview={() => load_admin_overview()}
        />
      ) : null}

      {visibleCfMarginModal ? (
        <CFMarginModal
          key={clientData?.id}
          setVisible={() => setVisibleCfMarginModal(false)}
          position={position}
          visible={visibleCfMarginModal}
          marginArray={clientData?.cf_margin}
          admin_id={props.data.id}
          load_admin_overview={() => load_admin_overview()}
        />
      ) : null}

      {visibleSegmentPendingModal ? (
        <SegmentPendingModal
          key={clientData?.id}
          setVisible={() => setVisibleSegmentPendingModal(false)}
          position={position}
          visible={visibleSegmentPendingModal}
          buttonsData={buttonsData}
          admin_id={props.data.id}
          load_admin_overview={() => load_admin_overview()}
        />
      ) : null}

      {visibleLiveSummaryModal ? (
        <LiveSummaryModal
          key={clientData?.id}
          usersId={clientData?.id}
          setVisible={() => setVisibleLiveSummaryModal(false)}
          position={position}
          visible={visibleLiveSummaryModal}
          //
          fut_multiplier={clientData?.fut_multiplier}
          mcx_multiplier={clientData?.mcx_multiplier}
          fo_multiplier={clientData?.fo_multiplier}
          //
          deposit={clientData?.deposit}
          blocked_limit={clientData?.blocked_limit}
          weekly_pl={clientData?.weekly_pl}
          clientUsername={clientData?.username}
        />
      ) : null}
    </>
  );
}
