import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import Php from "../../../Backend/Php";
import { useNotification } from "../../Notification/NotificationContext";
import { NewTheme } from "../../Theme/Theme";
import moment from "moment";
import NumberFormatIn from "../../Common/NumberFormatIn";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";

const php = new Php();
const height = window.innerHeight;

export default function CFLogs(props) {
  const [loading, setLoading] = React.useState(false);
  const [clientCFLogs, setClientCFLogs] = React.useState([]);
  const { addNotification } = useNotification();

  const [filters, setFilters] = React.useState({
    symbol: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  const [filtersGlobal, setFiltersGlobal] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const value = filtersGlobal["global"] ? filtersGlobal["global"].value : "";
  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filtersGlobal };

    _filters["global"].value = value;

    setFilters(_filters);
  };

  React.useEffect(() => {
    load_users_cflogs();
  }, []);

  const load_users_cflogs = (e) => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
        c_id: props.data.id,
      };

      php.load_users_cflogs(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          setClientCFLogs(r.cf_logs);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  return (
    <>
      <div className="card">
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginBottom: 10,
            height: 30,
          }}
        >
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 20,
            }}
          >
            <InputText
              style={{ width: 200, height: 30 }}
              type="search"
              value={value || ""}
              onChange={(e) => onGlobalFilterChange(e)}
              placeholder="Search"
            />
          </div>
        </div>
        <DataTable
          removableSort
          stripedRows
          showGridlines
          paginator
          scrollHeight={height - 150}
          scrollable
          // filters={filters}
          value={clientCFLogs}
          size="small"
          filters={filters}
          style={{ fontWeight: "bold", fontSize: 14 }}
          onFilter={(e) => setFilters(e.filters)}
          rowsPerPageOptions={[10, 25, 50, 100]}
          rows={25}
        >
          <Column
            sortable
            field="position_id"
            style={{ wordWrap: "break-word" }}
            showFilterMenu={false}
            header="#"
            body={(rowData) => <div>{rowData.position_id}</div>}
          />
          <Column
            style={{ width: "20%" }}
            showFilterMenu={false}
            field="symbol"
            header="Symbol"
            sortField="symbol"
            body={(rowData) => (
              <>
                <div
                  style={{
                    color: NewTheme.MainColor,
                  }}
                >
                  {rowData.symbol}
                </div>
                <span style={{ color: "gray" }}>
                  {moment(rowData.expiry_date).format("DD-MMM")}
                </span>
              </>
            )}
          />

          <Column
            style={{ width: "20%" }}
            showFilterMenu={false}
            field="qty"
            body={(rowData) => (
              <div
                style={{
                  color:
                    rowData.buy_sell == 0
                      ? NewTheme.greencolor
                      : NewTheme.redcolor,
                }}
              >
                <NumberFormatIn value={rowData.qty} fixed={1} />
              </div>
            )}
            header="Qty"
          />
          <Column
            style={{ width: "20%" }}
            sortable
            showFilterMenu={false}
            field="rate"
            header="Rate"
            body={(rowData) => (
              <div
                style={{
                  color: NewTheme.MainColor,
                }}
              >
                <NumberFormatIn value={rowData.rate} />
              </div>
            )}
          />
          <Column
            style={{ width: "20%" }}
            showFilterMenu={false}
            field="old_status"
            header="Status"
            body={(rowData) => (
              <div
                style={{
                  color: NewTheme.MainColor,
                }}
              >
                <span
                  style={{
                    color: rowData.old_status == 0 ? "red" : "green",
                  }}
                >
                  {rowData.old_status == 0 ? "OFF" : "ON"}
                </span>{" "}
                <span style={{ color: "gray" }}>{"=>"}</span>{" "}
                <span
                  style={{
                    color: rowData.new_status == 0 ? "red" : "green",
                  }}
                >
                  {rowData.new_status == 0 ? "OFF" : "ON"}
                </span>
              </div>
            )}
          />
          <Column
            sortable
            field="date_created"
            style={{ wordWrap: "break-word" }}
            showFilterMenu={false}
            header="Date/Time"
            body={(rowData) => (
              <div>
                {moment(rowData.date_created).format("DD-MMM-YY HH:mm:ss")}
              </div>
            )}
          />
        </DataTable>
      </div>
    </>
  );
}
