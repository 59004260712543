import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";

import { InputText } from "primereact/inputtext";
import Php from "../../Backend/Php";
import { useNotification } from "../Notification/NotificationContext";
import { FilterMatchMode } from "primereact/api";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { NewTheme } from "../Theme/Theme";
import moment from "moment/moment";
import { Calendar } from "primereact/calendar";
import TopbarMost from "../Common/TopbarMost";
import Filter from "./Filter/Filter";
import "jspdf-autotable";
import jsPDF from "jspdf";
const php = new Php();

const height = window.innerHeight;

export default function ForceSqOff() {
  const [pendingData, setPendingData] = useState([]);
  const [pendingDataFilter, setPendingDataFilter] = useState([]);

  const [usersFilter, setUsersFilter] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);

  const [pendingStatusCount, setPendingStatusCount] = useState(0);
  const [executedStatusCount, setExecutedStatusCount] = useState(0);
  const [cancelledStatusCount, setCancelledStatusCount] = useState(0);

  const { addNotification } = useNotification();
  const [loading, setLoading] = React.useState(false);
  const [dropdown, setDropdown] = useState("");
  const dt = useRef(null);

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [date, setDate] = useState([new Date(), new Date()]);

  const [filters, setFilters] = useState({
    symbol: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });
  const [filtersGlobal, setFiltersGlobal] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const value = filtersGlobal["global"] ? filtersGlobal["global"].value : "";
  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filtersGlobal };

    _filters["global"].value = value;

    setFilters(_filters);
  };

  React.useEffect(() => {
    load_force_sq_off_logs();
  }, []);

  const load_force_sq_off_logs = (e) => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
        from_date:
          e == "clear"
            ? moment(new Date()).format("YYYY-MM-DD 23:59:59")
            : moment(date[0]).format("YYYY-MM-DD 00:00:00"),
        to_date:
          e == "clear"
            ? moment(new Date()).format("YYYY-MM-DD 23:59:59")
            : moment(date[1]).format("YYYY-MM-DD 23:59:59"),
        c_id:
          selectedUser?.id == undefined || e == "clear" ? 0 : selectedUser?.id,
      };

      php.load_force_sq_off_logs(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          setPendingDataFilter(r.pendings);
          setPendingData(r.pendings);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const cols = [
    { dataKey: "server", title: "Server" },
    { dataKey: "master", title: "Master" },
    { dataKey: "username", title: "Username" },
    { dataKey: "date_created", title: "Date" },
    { dataKey: "rate_data", title: "Details" },
  ];

  const exportPdf = () => {
    // Your column and data definitions (replace these with your actual data)
    const doc = new jsPDF({
      orientation: "landscape",
    });

    doc.setFontSize(16);
    doc.text("Force Sq Off Report", 15, 15);

    pendingData.forEach((row) => {
      // Convert the value to a number for loose equality check
    });

    const headerStyles = {
      fillColor: "#4a6ba1",
      textColor: "#ffffff",
    };

    // Add content to the PDF using autoTable with modified data and custom header styles
    const tableStartY = 25; // Adjust the startY value based on the header size and layout
    doc.autoTable({
      head: [cols.map((col) => col.title)], // Use titles for the header
      body: pendingData.map((row) => cols.map((col) => row[col.dataKey])), // Map data to columns
      startY: tableStartY,
      headStyles: headerStyles,
      columnStyles: {
        rate_data: { cellWidth: "auto" }, // Adjust cell width as needed
      },
    });

    var pdfName =
      selectedUser?.username != undefined
        ? " Force Sq Off Report"
        : " Force Sq Off Report";
    // Save or display the PDF

    doc.save(pdfName + ".pdf");
  };

  const filterbar = (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: 20,
      }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 20,
        }}
      >
        <InputText
          style={{ width: 140, height: 30 }}
          type="search"
          id="search2"
          value={value || ""}
          onChange={(e) => onGlobalFilterChange(e)}
          placeholder="Search"
        />
      </div>
      <Filter
        selectedUser={selectedUser}
        usersFilter={usersFilter}
        setSelectedUser={(e) => setSelectedUser(e)}
        date={date}
        setDate={(e) => setDate(e)}
        clearFilter={() => load_force_sq_off_logs("clear")}
        reload={load_force_sq_off_logs}
      />
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        {/* <Button
          type="button"
          label="PDF"
          severity="warning"
          style={{
            height: 25,
            width: 80,
            paddingLeft: 10,
            paddingRight: 10,
            fontSize: 12,
          }}
          onClick={exportPdf}
          data-pr-tooltip="PDF"
        /> */}
      </div>
    </div>
  );

  return (
    <div
      className="card"
      style={{
        padding: "0.5rem",
        paddingTop: 0,
        borderRadius: "10px",
        overflow: "hidden",
      }}
    >
      <TopbarMost name="Force Sq Off" cmp={filterbar} />

      <div id={"stick"} style={{ position: "relative" }}>
        <DataTable
          removableSort
          stripedRows
          showGridlines
          scrollable
          scrollHeight={height - 150}
          size="small"
          filters={filters}
          // filterDisplay="row"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          value={pendingData}
          style={{ fontWeight: "bold", fontSize: 14, borderColor: "red" }}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          globalFilterFields={["username", "master", "admin", "server"]}
          loading={loading}
          paginator
          rows={10}
          onFilter={(e) => setFilters(e.filters)}
          rowsPerPageOptions={[10, 25, 50, 100]}
        >
          <Column field="server" showFilterMenu={false} header="Server" />
          <Column
            field="master"
            showFilterMenu={false}
            header="Master"
            sortable
          />
          <Column
            field="username"
            showFilterMenu={false}
            header="Client"
            sortable
          />

          <Column
            field="rate_data"
            showFilterMenu={false}
            header="Details"
            body={(rowData) => {
              const data = JSON.parse(rowData.rate_data);
              return (
                <div>
                  <div>
                    <strong>Symbol:</strong> {data.Symbol}
                  </div>
                  <div>
                    <strong>Last Update:</strong> {data.LastUpdateTime}
                  </div>
                  <div>
                    <strong>LTP:</strong> {data.LTP}
                  </div>
                  <div>
                    <strong>Open:</strong> {data.Open}
                  </div>
                  <div>
                    <strong>High:</strong> {data.High}
                  </div>
                  <div>
                    <strong>Low:</strong> {data.Low}
                  </div>
                  <div>
                    <strong>Previous Close:</strong> {data.Previous_Close}
                  </div>
                  <div>
                    <strong>Today OI:</strong> {data.Today_OI}
                  </div>
                  <div>
                    <strong>Bid:</strong> {data.Bid}
                  </div>
                  <div>
                    <strong>Ask:</strong> {data.Ask}
                  </div>
                  <div>
                    <strong>Timestamp:</strong> {data.Timestamp}
                  </div>
                </div>
              );
            }}
          />
          <Column
            field="date_created"
            style={{ wordWrap: "break-word" }}
            showFilterMenu={false}
            header="Date/Time"
            body={(rowData) => (
              <div>
                {moment(rowData.date_created).format("DD-MMM ")}
                <br />
                <span style={{ color: NewTheme.MainColor }}>
                  {moment(rowData.date_created).format("HH:mm:ss")}
                </span>
              </div>
            )}
            sortable
          />
        </DataTable>
      </div>
    </div>
  );
}
