import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useRef, useState } from "react";

import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment/moment";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import Php from "../../Backend/Php";
import NumberFormatIn from "../Common/NumberFormatIn";
import TopbarMost from "../Common/TopbarMost";
import { useNotification } from "../Notification/NotificationContext";
import { NewTheme } from "../Theme/Theme";
import Filter from "./Filter/Filter";
import WithdrawModal from "./Modal/WithdrawModal";
const php = new Php();

const height = window.innerHeight;

export default function Withdraw() {
  const [depositData, setDepositData] = useState([]);
  const [depositDataFilter, setDepositDataFilter] = useState([]);

  const [mastersFilter, setMastersFilter] = useState([]);
  const [mastersFilter2, setMastersFilter2] = useState([]);
  const [selectedMaster, setSelectedMaster] = useState([]);
  const [mastersFilterMain, setMastersFilterMain] = useState([]);

  const [selectedAdmin, setSelectedAdmin] = useState([]);
  const [adminsFilter, setAdminsFilter] = useState([]);

  const [usersFilter, setUsersFilter] = useState([]);
  const [usersFilter2, setUsersFilter2] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);

  const [totalWithdraw, setTotalWithdraw] = useState(0);
  const [pendingStatusCount, setPendingStatusCount] = useState(0);
  const [executedStatusCount, setExecutedStatusCount] = useState(0);
  const [cancelledStatusCount, setCancelledStatusCount] = useState(0);

  const { addNotification } = useNotification();
  const [loading, setLoading] = React.useState(false);
  const [dropdown, setDropdown] = useState("");
  const dt = useRef(null);

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [date, setDate] = useState([new Date(), new Date()]);

  const [filters, setFilters] = useState({
    symbol: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  const [filterSymbol, setFilterSymbol] = React.useState([]);
  const [filterUser, setFilterUser] = React.useState([]);

  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState("top");
  const [modalData, setModalData] = React.useState([]);

  const show = (depositData) => {
    setModalData(depositData);
    setVisible(true);
  };

  useEffect(() => {
    load_withdraw();
  }, []);

  const load_withdraw = (e) => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
        from_date:
          e == "clear"
            ? moment(new Date()).format("YYYY-MM-DD 23:59:59")
            : moment(date[0]).format("YYYY-MM-DD 00:00:00"),
        to_date:
          e == "clear"
            ? moment(new Date()).format("YYYY-MM-DD 23:59:59")
            : moment(date[1]).format("YYYY-MM-DD 23:59:59"),
        c_id:
          selectedUser?.id == undefined || e == "clear" ? 0 : selectedUser?.id,
        a_id:
          selectedAdmin?.id == undefined || e == "clear"
            ? 0
            : selectedAdmin?.id,
        m_id:
          selectedMaster?.id == undefined || e == "clear"
            ? 0
            : selectedMaster?.id,
      };

      php.load_withdraw(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          setUsersFilter(r.users);
          setUsersFilter2(r.users);
          setMastersFilter(r.masters);
          setMastersFilter2(r.masters);
          setDepositDataFilter(r.withdraws);
          setDepositData(r.withdraws);
          setTotalWithdraw(r.total);

          let pendingStatus = r.withdraws.filter((x) => x.status == "0");
          let executedStatus = r.withdraws.filter((x) => x.status == "1");
          let cancelledStatus = r.withdraws.filter((x) => x.status == "2");

          setAdminsFilter(r.admins);
          setPendingStatusCount(pendingStatus.length);
          setExecutedStatusCount(executedStatus.length);
          setCancelledStatusCount(cancelledStatus.length);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const filterMaster = (masterData) => {
    let master_id = masterData?.id;
    if (master_id > 0) {
      let newUserArray = usersFilter2.filter((x) => x.master_id == master_id);
      setUsersFilter(newUserArray);
    } else {
      setUsersFilter(usersFilter2);
      setSelectedUser({});
    }
    setSelectedMaster(masterData);
  };

  const filterAdmin = (adminData) => {
    let admin_id = adminData?.id;
    if (admin_id > 0) {
      let newUserArray = usersFilter2.filter((x) => x.admin_id == admin_id);
      setUsersFilter(newUserArray);
      let newMasterArray = mastersFilter2.filter((x) => x.admin_id == admin_id);
      setMastersFilter(newMasterArray);
    } else {
      setUsersFilter(usersFilter2);
      setSelectedUser({});
      setMastersFilter(mastersFilter2);
      setSelectedMaster({});
    }
    setSelectedAdmin(adminData);
  };

  const cols = [
    { dataKey: "username", title: "Username" },
    { dataKey: "symbol", title: "Symbol" },
    { dataKey: "qty", title: "Qty" },
    { dataKey: "buy_sell", title: "B/S" },
    { dataKey: "price", title: "Price" },
    { dataKey: "high_rate", title: "High" },
    { dataKey: "low_rate", title: "Low" },
    { dataKey: "highlow_mid", title: "HL/MID" },
    { dataKey: "type", title: "Type" },
    { dataKey: "tick_time", title: "Tick Time" },
    { dataKey: "time_buy", title: "Process Time" },
    { dataKey: "process_msg", title: "Details" },
    { dataKey: "date_created", title: "Date" },
  ];

  const exportPdf = () => {
    // Your column and data definitions (replace these with your actual data)
    const doc = new jsPDF({
      orientation: "landscape",
    });

    doc.setFontSize(16);
    doc.text("Pending Report", 15, 15);

    depositData.forEach((row) => {
      // Convert the value to a number for loose equality check
      const highLowValue = Number(row.highlow_mid);
      const buySellValue = Number(row.buy_sell);
      const slTpValue = Number(row.sl_tp);

      // Update the values based on conditions
      row.highlow_mid = highLowValue == 0 ? "High-Low" : "Mid-Limit";
      row.buy_sell = buySellValue == 0 ? "Buy" : "Sell";
      row.type = buySellValue == 0 ? "Buy" : "Sell"; // Assuming 'type' is a string property

      // Determine 'sl_tp' based on its value
      if (slTpValue == 0) {
        row.type += "-Stop";
      } else {
        row.type += "-Target";
      }
    });

    const headerStyles = {
      fillColor: "#4a6ba1",
      textColor: "#ffffff",
    };

    // Add content to the PDF using autoTable with modified data and custom header styles
    const tableStartY = 25; // Adjust the startY value based on the header size and layout
    doc.autoTable(cols, depositData, { startY: tableStartY, headerStyles });

    var pdfName =
      selectedUser?.username != undefined
        ? selectedUser?.username.toUpperCase() + " - Pending Report"
        : "(S)" +
          localStorage.getItem("server").toUpperCase() +
          " (M)" +
          localStorage.getItem("adminsUsername")?.toUpperCase() +
          " - Pending Report";
    // Save or display the PDF

    doc.save(pdfName + ".pdf");
  };

  const [filtersGlobal, setFiltersGlobal] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const value = filtersGlobal["global"] ? filtersGlobal["global"].value : "";
  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filtersGlobal };

    _filters["global"].value = value;

    setFilters(_filters);
  };

  const filterbar = (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: 20,
      }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 12 }}>Total Withdraw</div>
        <div style={{ fontSize: 18, color: NewTheme.MainColor }}>
          <NumberFormatIn value={totalWithdraw} />
        </div>
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 12 }}>Pending</div>
        <div style={{ fontSize: 18, color: NewTheme.MainColor }}>
          <NumberFormatIn value={pendingStatusCount} />
        </div>
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 12 }}>Accepted</div>
        <div style={{ fontSize: 18, color: NewTheme.greencolor }}>
          <NumberFormatIn value={executedStatusCount} />
        </div>
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 12 }}>Cancelled</div>
        <div style={{ fontSize: 18, color: NewTheme.redcolor }}>
          <NumberFormatIn value={cancelledStatusCount} />
        </div>
      </div>
    </div>
  );

  const searchBar = (
    <>
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: 20,
        }}
      >
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 20,
          }}
        >
          <InputText
            style={{ width: 140, height: 30 }}
            type="search"
            value={value || ""}
            onChange={(e) => onGlobalFilterChange(e)}
            placeholder="Search"
          />
        </div>

        <Filter
          selectedMaster={selectedMaster}
          selectedAdmin={selectedAdmin}
          setSelectedMaster={(e) => filterMaster(e)}
          setSelectedAdmin={(e) => filterAdmin(e)}
          selectedUser={selectedUser}
          usersFilter={usersFilter}
          mastersFilter={mastersFilter}
          adminsFilter={adminsFilter}
          setSelectedUser={(e) => setSelectedUser(e)}
          date={date}
          setDate={(e) => setDate(e)}
          clearFilter={() => load_withdraw("clear")}
          reload={() => load_withdraw()}
        />

        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 10,
          }}
        >
          {/* <Button
            type="button"
            label="PDF"
            severity="warning"
            style={{
              height: 25,
              width: 80,
              paddingLeft: 10,
              paddingRight: 10,
              fontSize: 12,
            }}
            onClick={exportPdf}
            data-pr-tooltip="PDF"
          /> */}
        </div>
      </div>
    </>
  );

  return (
    <div
      className="card"
      style={{
        padding: "0.5rem",
        paddingTop: 0,
        borderRadius: "10px",
        overflow: "hidden",
      }}
    >
      <TopbarMost name="Withdraw" cmp={filterbar} />
      <TopbarMost
        height={45}
        background={"#ffffff"}
        noSidebar
        cmp={searchBar}
      />
      <div id={"stick"} style={{ position: "relative" }}>
        <DataTable
          removableSort
          stripedRows
          showGridlines
          scrollable
          scrollHeight={height - 150}
          size="small"
          filters={filters}
          // filterDisplay="row"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          value={depositData}
          style={{ fontWeight: "bold", fontSize: 14, borderColor: "red" }}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          // globalFilterFields={["username", "symbol"]}
          paginator
          rows={10}
          loading={loading}
          rowsPerPageOptions={[10, 25, 50, 100]}
        >
          <Column
            field="admin"
            showFilterMenu={false}
            header="Admin"
            sortable
            body={(rowData) => (
              <div>{rowData.admin == "" ? "----" : rowData.admin}</div>
            )}
          />
          <Column
            field="master"
            showFilterMenu={false}
            header="Master"
            sortable
            body={(rowData) => (
              <div>
                <span
                  style={{ color: rowData.master == "NIL" ? "gray" : "black" }}
                >
                  {rowData.master}
                </span>
              </div>
            )}
          />
          <Column
            field="username"
            showFilterMenu={false}
            header="Client"
            sortable
          />

          <Column
            field="amount"
            body={(rowData) => (
              <div>
                <NumberFormatIn value={rowData.amount} />
              </div>
            )}
            showFilterMenu={false}
            header="Amount"
            sortable
          />

          <Column
            field="order_id"
            filter
            showFilterMenu={false}
            header="Order Id"
            body={(rowData) => <div>{rowData.order_id}</div>}
            sortable
          />
          <Column
            field="withdraw_type"
            filter
            showFilterMenu={false}
            header="Type"
            body={(rowData) => <div>{rowData.withdraw_type}</div>}
            sortable
          />
          <Column
            field="user_transaction_id"
            sortable
            filter
            showFilterMenu={false}
            header="Details"
            body={(rowData) => (
              <div>
                {rowData.withdraw_type == "UPI" ? (
                  <div>
                    <span style={{ color: "gray" }}>Upi : </span>
                    {rowData.upi_id}
                  </div>
                ) : (
                  <>
                    <div>
                      <span style={{ color: "gray" }}>Bank : </span>
                      {rowData.bank_name}
                    </div>
                    <div>
                      <span style={{ color: "gray" }}>Name : </span>
                      {rowData.bank_beneficiary}
                    </div>
                    <div>
                      <span style={{ color: "gray" }}>A/C : </span>
                      {rowData.bank_acc_no}
                    </div>
                    <div>
                      <span style={{ color: "gray" }}>IFSC : </span>
                      {rowData.bank_ifsc}
                    </div>
                  </>
                )}
              </div>
            )}
          />

          <Column
            field="status_msg"
            sortable
            body={(rowData) => (
              <div
                style={{
                  color:
                    rowData.status == 0
                      ? NewTheme.MainColor
                      : rowData.status == 1
                      ? NewTheme.greencolor
                      : NewTheme.redcolor,
                }}
              >
                <span>{rowData.status_msg}</span>
              </div>
            )}
            showFilterMenu={false}
            header="Status"
          />

          <Column
            field="remarks"
            filter
            showFilterMenu={false}
            header="Remarks"
            body={(rowData) => (
              <>
                {rowData.remarks == "" ? null : (
                  <div style={{ fontSize: 12 }}>
                    <span style={{ color: "gray" }}>User : </span>
                    {rowData.remarks}
                  </div>
                )}
                {rowData.admin_remarks == "" ||
                rowData.admin_remarks == null ? null : (
                  <div style={{ fontSize: 12 }}>
                    <span style={{ color: "gray" }}>Admin : </span>
                    {rowData.admin_remarks}
                  </div>
                )}
              </>
            )}
          />

          <Column
            field="Balance"
            filter
            showFilterMenu={false}
            header="Balance"
            body={(rowData) => (
              <>
                <div>
                  <span style={{ color: "gray" }}>B:</span>
                  <NumberFormatIn value={parseFloat(rowData.balance_before)} />
                </div>
                <div>
                  <span style={{ color: "gray" }}>A:</span>
                  <NumberFormatIn value={parseFloat(rowData.balance_after)} />
                </div>
              </>
            )}
          />
          <Column
            field="date_created"
            style={{ wordWrap: "break-word" }}
            showFilterMenu={false}
            header="Date/Time"
            body={(rowData) => (
              <div>
                {moment(rowData.date_created).format("DD-MMM-YY ")}
                <br />
                <span style={{ color: NewTheme.MainColor }}>
                  {moment(rowData.date_created).format("HH:mm:ss")}
                </span>
              </div>
            )}
            sortable
          />
          <Column
            field="date_created"
            style={{ wordWrap: "break-word" }}
            showFilterMenu={false}
            header=""
            body={(rowData) => (
              <div style={{ display: "flex", gap: 5 }}>
                <Button
                  type="button"
                  icon="pi pi-info"
                  style={{
                    height: 25,
                    width: 25,
                    backgroundColor: NewTheme.MainColor,
                  }}
                  onClick={() => show(rowData)}
                  data-pr-tooltip="PDF"
                />
              </div>
            )}
          />
        </DataTable>
      </div>
      {visible && (
        <WithdrawModal
          key={new Date()}
          setVisible={() => {
            setVisible(false);
            load_withdraw();
          }}
          position={position}
          visible={visible}
          depositData={modalData}
        />
      )}
    </div>
  );
}
