import { Button } from "primereact/button";
import React, { useState } from "react";
import Php from "../../../Backend/Php";
import { useNotification } from "../../Notification/NotificationContext";
import { NewTheme } from "../../Theme/Theme";
import LiveSummaryModal from "./LiveSummaryModal";
import MultiplierModal from "./MultiplierModal";
import {
  BlackText,
  BlueText,
  BoldText,
  ButtonGrid,
  CreateClientButton,
  FlexContainer,
  FlexItem,
  GrayText,
  GreenText,
  Wrapper,
} from "./Styled";
import NumberFormatIn from "../../Common/NumberFormatIn";

const php = new Php();

export default function Overview(props) {
  const [loading, setLoading] = React.useState(false);
  const [buttonsData, setButtonsData] = React.useState([]);
  const [clientData, setClientData] = React.useState([]);
  const { addNotification } = useNotification();
  const [timeDiff, setTimeDiff] = useState(0);

  React.useEffect(() => {
    load_master_overview();
  }, [props.data.id]);

  const load_master_overview = () => {
    if (!loading) {
      setLoading(true);
      let data2 = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
        a_id: props.data.admin_id,
        m_id: props.data.id,
      };

      php.load_master_overview(data2).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          setClientData(r.masters_overview[0]);
          setButtonsData(r.buttons_data);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  function handleButtonClick(key) {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
        m_id: clientData.id,
        a_id: props.data.admin_id,
        button_key: key,
      };

      php.change_masters_setting(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          load_master_overview();
          if (r.on_off == "0") {
            addNotification(r.message, "error");
          } else {
            addNotification(r.message, "success");
          }
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  }

  const handleStatusButtonClick = () => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
        m_id: clientData.id,
        a_id: props.data.admin_id,
      };

      php.change_masters_status(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          load_master_overview();
          if (r.on_off == "0") {
            addNotification(r.message, "error");
          } else {
            addNotification(r.message, "success");
          }
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const handleBalanceButtonClick = () => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        g_id: localStorage.getItem("godsId"),
        m_id: clientData.id,
        a_id: props.data.admin_id,
      };

      php.change_masters_balance(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          load_master_overview();
          if (r.on_off == "0") {
            addNotification(r.message, "error");
          } else {
            addNotification(r.message, "success");
          }
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const [visible, setVisible] = useState(false);
  const [visibleLiveSummaryModal, setVisibleLiveSummaryModal] = useState(false);
  const [position, setPosition] = useState("top");

  const show = (position) => {
    setPosition("top");
    setVisible(true);
  };

  const showLiveSummaryModal = (position) => {
    setPosition("top");
    setVisibleLiveSummaryModal(true);
  };

  return (
    <>
      <Wrapper>
        <FlexContainer>
          <FlexItem>
            <GrayText>Master Name</GrayText>
            <BoldText>
              <span style={{ color: "gray" }}>
                {clientData?.alias == null || clientData?.alias == ""
                  ? ""
                  : clientData?.alias + " | "}
              </span>
              {clientData?.name}
            </BoldText>
          </FlexItem>
          <FlexItem>
            <GrayText>Master Username</GrayText>
            <BlueText>{clientData?.username}</BlueText>
          </FlexItem>
          <FlexItem>
            <GrayText>Weekly Profit Loss</GrayText>
            <GreenText
              style={{
                color:
                  clientData?.weekly_pl >= 0
                    ? NewTheme.greencolor
                    : NewTheme.redcolor,
              }}
            >
              <NumberFormatIn value={parseFloat(clientData?.weekly_pl)} />
            </GreenText>
          </FlexItem>
        </FlexContainer>
        <FlexContainer>
          <FlexItem>
            <GrayText>Margin</GrayText>
            <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
              <BlackText>{clientData?.margin}</BlackText>
              {/* <Button
                label="Edit"
                outlined
                aria-label="Filter"
                size="small"
                style={{
                  padding: 0,
                  height: "25px",
                  width: "40px",
                }}
                onClick={balanceAdd}
              /> */}
            </div>
          </FlexItem>
          <FlexItem>
            <GrayText>Total Clients</GrayText>
            <BoldText>{clientData?.users}</BoldText>
          </FlexItem>
          <FlexItem>
            <GrayText>Last Client</GrayText>
            <BoldText>{clientData?.last_login}</BoldText>
          </FlexItem>
        </FlexContainer>
        <FlexContainer>
          <FlexItem>
            <GrayText>Multiplier</GrayText>
            <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <BoldText>Min : {clientData?.min_multiplier}x </BoldText>
                <BoldText> Max : {clientData?.max_multiplier}x </BoldText>
              </div>
              <Button
                label="Edit"
                outlined
                aria-label="Filter"
                size="small"
                style={{
                  padding: 0,
                  height: "25px",
                  width: "40px",
                }}
                onClick={() => show()}
              />
            </div>
          </FlexItem>
          <FlexItem>
            {/* <GrayText>
              ADMIN{" "}
              <span style={{ color: "black", fontWeight: "bold" }}>
                {clientData?.super?.toUpperCase()}
              </span>
            </GrayText> */}
            <GrayText>
              Server{" "}
              <span style={{ color: "black", fontWeight: "bold" }}>
                {clientData?.server?.toUpperCase()}
              </span>
            </GrayText>
          </FlexItem>
          <FlexItem>
            <GrayText>Otp</GrayText>
            <BoldText>{clientData?.otp}</BoldText>
          </FlexItem>
        </FlexContainer>
      </Wrapper>

      <ButtonGrid>
        <CreateClientButton
          active={true}
          color={NewTheme.MainColor}
          onClick={() => showLiveSummaryModal()}
        >
          {"LIVE REPORT"}
        </CreateClientButton>
        <CreateClientButton
          active={clientData?.status == 1 ? true : false}
          color={"#22c55e"}
          onClick={() => handleStatusButtonClick()}
        >
          {"ACTIVE"}
        </CreateClientButton>
        <CreateClientButton
          active={clientData?.balance_edit == 1 ? true : false}
          color={"#e172d9"}
          onClick={() => handleBalanceButtonClick()}
        >
          {"BALANCE EDIT"}
        </CreateClientButton>

        {buttonsData.map((button, index) => (
          <CreateClientButton
            key={index}
            active={button.active}
            color={button.color}
            onClick={() => handleButtonClick(button.id)} // Add the onClick handler
          >
            {button.text}
          </CreateClientButton>
        ))}
      </ButtonGrid>

      {visible ? (
        <MultiplierModal
          key={clientData?.id}
          setVisible={() => setVisible(false)}
          position={position}
          visible={visible}
          max_multiplier={clientData?.max_multiplier}
          min_multiplier={clientData?.min_multiplier}
          master_id={props.data.id}
          load_master_overview={() => load_master_overview()}
        />
      ) : null}

      {visibleLiveSummaryModal ? (
        <LiveSummaryModal
          key={clientData?.id}
          usersId={clientData?.id}
          adminId={clientData?.admin_id}
          setVisible={() => setVisibleLiveSummaryModal(false)}
          position={position}
          visible={visibleLiveSummaryModal}
          //
          fut_multiplier={clientData?.fut_multiplier}
          mcx_multiplier={clientData?.mcx_multiplier}
          fo_multiplier={clientData?.fo_multiplier}
          //
          deposit={clientData?.deposit}
          blocked_limit={clientData?.blocked_limit}
          weekly_pl={clientData?.weekly_pl}
          clientUsername={clientData?.username}
        />
      ) : null}
    </>
  );
}
