import React from "react";
import { NumericFormat } from "react-number-format";
import { NewTheme } from "../Theme/Theme";
export default function NumberFormatIn({
  value,
  fixed,
  color,
  colorOnValue,
  showDecimalFixed,
}) {
  const defaultColor = "#000000";

  const getColor = () => {
    if (colorOnValue) {
      return parseFloat(value) > 0
        ? NewTheme.greencolor
        : parseFloat(value) < 0
        ? NewTheme.redcolor
        : defaultColor;
    }
    return color || defaultColor;
  };

  const formattedValue = parseFloat(value);
  const shouldFormat = fixed >= 0;
  const decimals = fixed || 0;

  const [whole, decimal] = value ? value.toString().split(".") : ["", ""];
  const isLessThanOne = Math.abs(formattedValue) < 1 && formattedValue !== 0;
  const isZero = formattedValue == 0;

  return (
    <>
      {isZero ? (
        // For zero value
        <span
          {...(colorOnValue || color ? { style: { color: getColor() } } : {})}
        >
          0
        </span>
      ) : isLessThanOne ? (
        // For numbers less than 1
        <span
          {...(colorOnValue || color ? { style: { color: getColor() } } : {})}
        >
          <span
            style={{
              fontSize: 10,
              opacity: 0.7,
              color: getColor(),
            }}
          >
            0
          </span>
          .{parseFloat(decimal)}
        </span>
      ) : (
        // For numbers >= 1
        <>
          <NumericFormat
            value={
              shouldFormat
                ? formattedValue.toFixed(decimals)
                : parseFloat(formattedValue)
            }
            displayType={"text"}
            thousandsGroupStyle="lakh"
            thousandSeparator={true}
            {...(colorOnValue || color ? { style: { color: getColor() } } : {})}
          />
          {/* {showDecimal && decimal && (
            <>
              <span
                {...(colorOnValue || color
                  ? { style: { color: getColor() } }
                  : {})}
              >
                .
                <span
                  style={{
                    fontSize: 10,
                    opacity: 0.7,
                    color: getColor(),
                  }}
                >
                  {" " + parseFloat(decimal)}
                </span>
              </span>
            </>
          )} */}
        </>
      )}
    </>
  );
}
