import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import React from "react";
import VirtualizedSelect from "react-virtualized-select";

import "react-select/dist/react-select.css";
import "react-virtualized/styles.css";
import "react-virtualized-select/styles.css";

export default function Filter(props) {
  return (
    <>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
        }}
      >
        <VirtualizedSelect
          value={props.selectedUser}
          style={{
            width: 250,
            zIndex: 999,
            textAlign: "left",
          }}
          labelKey="username"
          options={props.usersFilter}
          valueKey="id"
          placeholder="Filter Username"
          onChange={(e) => {
            console.log(e);
            if (e == undefined) {
              props.setSelectedUser();
            } else {
              props.setSelectedUser(e);
            }
          }}

          // value={this.state.selectValue}
        />
      </div>

      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <Calendar
          dateFormat="dd/mm/yy"
          style={{ width: 210 }}
          selectionMode="range"
          id="calendar-12h"
          value={props.date}
          onChange={(e) => props.setDate(e.value)}
        />
      </div>

      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <Button
          type="button"
          // icon="pi pi-filter"
          severity="success"
          label="SUBMIT"
          style={{
            height: 25,
            width: 80,
            fontSize: 12,
            padding: 10,
          }}
          onClick={() => props.load_pendings()}
          data-pr-tooltip="XLS"
        />
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
        }}
      >
        <Button
          type="button"
          // icon="pi pi-times"
          label="CLEAR"
          severity="danger"
          style={{
            height: 25,
            width: 80,
            fontSize: 12,
            padding: 10,
          }}
          onClick={() => {
            props.clearFilter();
          }}
          data-pr-tooltip="XLS"
        />
      </div>
    </>
  );
}
